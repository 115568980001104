import { UserSubscriptionDetails } from '@anm/api/modules/user';
import { User } from 'user';

import { ApplicationState } from '../../types';

export const selectProviderAndMethod = ({ auth: { method, provider } }: ApplicationState) => ({ method, provider });

export const isNewUser = ({ auth }: ApplicationState) => auth.isNew;

export const isUserLogged = ({ auth, user }: ApplicationState) => auth.isLoggedIn || !!user.profile;

export const selectAuth = ({ auth }: ApplicationState) => auth;

export const selectAuthError = ({ auth }: ApplicationState) => auth.error;
export const selectSessionError = ({ auth }: ApplicationState) => auth.sessionError;
export const selectLogoutError = ({ auth }: ApplicationState) => auth.logout.isError;

export const selectAuthToken = ({ auth }: ApplicationState) => auth.token;

export const selectAuthLoginStatus = ({ auth }: ApplicationState) => auth.isLoggedIn;

export const selectAuthPending = ({ auth }: ApplicationState) => auth.isPending;
export const selectSignUpAnonPending = ({ auth }: ApplicationState) => auth.anon.isPending;
export const selectLogoutPending = ({ auth }: ApplicationState) => auth.logout.isPending;

export const getSubscriptionDetails = (user: User) =>
  user.subscriptionDetails.find((subscription: UserSubscriptionDetails) => subscription.product === 'WAVE')!;

export const getUserProjectsLimitCount = (user: User) =>
  getSubscriptionDetails(user).features.PrivateUnlistedProjects.numProjects;
