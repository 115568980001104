import { connect } from 'react-redux';

import { update } from '../store/modules/appMeta';
import { ApplicationState } from '../store/types';

export type WithAppMetaProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const mapStateToProps = ({ appMeta }: ApplicationState) => ({
  appMeta: {
    ...appMeta,
    isWave: appMeta.product === 'WAVE'
  }
});

const mapDispatchToProps = { update };

export default connect(mapStateToProps, mapDispatchToProps);
