import liveStreamApi from '@anm/api/instances/liveStreamApi';
import mainApi from '@anm/api/instances/mainApi';
import nodeStreamApi from '@anm/api/instances/nodeStreamApi';
import { ApiConfig } from '@anm/api/types';
import * as cookie from '@anm/helpers/cookie';
import { setUploadsApi } from '@anm/store/modules/uploads/api';

import vtConfig from '../config';

const { auth, urls, nextDevMode } = vtConfig();
const getDefaultConfig = (): ApiConfig => ({
  token: cookie.get(auth.cookieName),
  baseURL: urls.api,
  isDebug: nextDevMode,
  hasContext: false
});

const setupApi = (newConfig: Partial<ApiConfig> = {}) => {
  nodeStreamApi({ ...getDefaultConfig(), ...newConfig, baseURL: urls.streamingRoomApi });
  liveStreamApi({ ...getDefaultConfig(), ...newConfig, baseURL: urls.streamingLiveApi });

  const api = mainApi({
    ...getDefaultConfig(),
    hasContext: true,
    ...newConfig
  });

  setUploadsApi(api.uploads);
};

export default setupApi;
