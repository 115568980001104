import createError from '@anm/helpers/error/createError';

import Crud from '../../helpers/crud';
import { Request } from '../../types';

import {
  CategoryListResponse,
  CurrentTemplateParams,
  SendTemplateLinkToEmailProps,
  TagsResponse,
  TemplateCategoryParams,
  TemplateItem,
  TemplateListParams,
  TemplateListResponse,
  TemplateTagParams,
  UpdateTemplateParams
} from '.';
export * from './types';

const getTags = (request: Request) => (params: TemplateTagParams) =>
  request<TagsResponse>({
    params,
    method: 'GET',
    url: 'template-tags',
  });

const getCategories = (request: Request) => (params: TemplateCategoryParams) =>
  request<CategoryListResponse>({
    params,
    method: 'GET',
    url: 'template-categories',
  });

const templatesCrud = (request: Request) =>
  new Crud({
    request,
    id: 'templateIdOrSlug',
    baseURL: 'templates',
  });

const updateTemplate = (request: Request) => ({ templateId, ...data }: UpdateTemplateParams) =>
  request<TemplateItem>({
    data,
    method: 'PUT',
    url: `templates/${templateId}`,
  });

const sendTemplateLinkToEmail = (request: Request) => (data: SendTemplateLinkToEmailProps) =>
  request<TemplateItem>({
    data,
    method: 'POST',
    url: `/template-util/mobile`,
  });

const templates = (request: Request) => {
  const { read } = templatesCrud(request);
  return {
    getTemplateList: (params: TemplateListParams) => read<TemplateListParams, TemplateListResponse>(params),
    getTemplateById: (templateIdOrSlug: string) => read<CurrentTemplateParams, TemplateItem>({ templateIdOrSlug }),
    getTemplateBySlug: (slug: string) =>
      read<TemplateListParams, TemplateListResponse>({ slug }).then(
        ({ content }) => content[0] || Promise.reject(createError(404, 'Not found'))
      ),
    getTags: getTags(request),
    getCategories: getCategories(request),
    updateTemplate: updateTemplate(request),
    sendTemplateLinkToEmail: sendTemplateLinkToEmail(request)
  };
};

export default templates;
