import { ApiError } from '@anm/api';
import getErrorByStatusCode, { StatusCode } from '@anm/data/errors';
import useDependPendingCallback from '@anm/hooks/useDependPendingCallback';
import usePageParams from '@anm/hooks/usePageParams';
import useSwitchState from '@anm/hooks/useSwitchState';
import { appNotificationActions } from '@anm/store/modules/appNotifications';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import getRouterName from '../helpers/router/getRouterName';
import routes from '../routes';
import { userActions, userSelectors } from '../store/modules/user';

const serverErrorsMap: { [key: string]: StatusCode } = {
  'Not found': 498
};

const { Router } = routes;
export const CONFIRM_EMAIL_PARAM_NAME = 'confirmEmail' as const;

const useErrorNotification = (error?: ApiError | boolean) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!error) {
      const { code, message } = error as ApiError;
      const { title, description } = getErrorByStatusCode(serverErrorsMap[message] || code);

      dispatch(
        appNotificationActions.notify({
          type: 'error',
          title,
          description
        })
      );
    }
  }, [!!error]);
};
const useSuccessNotification = (showNotify: boolean) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!showNotify) {
      dispatch(
        appNotificationActions.notify({
          type: 'success',
          title: 'Your email has been successfully confirmed'
        })
      );
    }
  }, [!!showNotify]);
};

const useEmailConfirmation = () => {
  const [isRequestCompleted, completeRequest] = useSwitchState();
  const isConfirmPending = useSelector(userSelectors.selectUserConfirmEmailPending);
  const isError = useSelector(userSelectors.selectUserConfirmEmailIsError);
  const error = useSelector(userSelectors.selectUserConfirmEmailError);
  const profile = useSelector(userSelectors.selectUserProfile);
  const isUserPending = useSelector(userSelectors.selectUserPending);
  const { confirmEmail: hash, ...otherParams } = usePageParams<{ [CONFIRM_EMAIL_PARAM_NAME]: string }>();
  const isSuccessConfirmed = isRequestCompleted && !!hash && !!profile && profile?.emailConfirmed;

  const dispatch = useDispatch();

  useEffect(() => {
    if (hash && !!profile && !profile?.emailConfirmed && !isConfirmPending && !isUserPending && !isError) {
      dispatch(userActions.confirmEmail(hash));
    }
  }, [isConfirmPending, isError, !!error, profile?.userId, hash, isUserPending]);

  useDependPendingCallback(isConfirmPending, completeRequest);
  useSuccessNotification(isSuccessConfirmed);
  useErrorNotification(isRequestCompleted && isError && (error as ApiError));

  useEffect(() => {
    if (isSuccessConfirmed || (hash && profile?.emailConfirmed)) {
      const route = getRouterName();
      route?.name && Router.pushRoute(route?.name, { ...route.params, ...otherParams }, { shallow: true });
    }
  }, [hash, isSuccessConfirmed, !!hash]);
};

export default useEmailConfirmation;
