const formId = 'K6si1N';
const getUrl = (email: string) =>
  `https://animatron.typeform.com/to/${formId}?email=${email}`;

export const openCancelForm = (email: string) => {
  window.open(getUrl(email), '_self');
};

export const openDeleteForm = (email: string) => {
  window.open(getUrl(email), '_self');
};
