import { Request } from '@anm/api';
import createRetry from '@anm/api/helpers/createRetry';
import { getAuthToken } from '@anm/auth/helpers/authToken';
import { SECURE_TOKEN_HEADER_NAME } from '@anm/constants/auth';
import { RoomComment } from '@anm/shared/types/room';
import { AxiosResponse } from 'axios';
import { DestinationList, DestinationObject, LiveStream } from 'types/stream';

import {
  ChangeKindParams,
  CreateStreamRequest,
  DeleteStreamProps,
  DuplicateRequestParams,
  EditStreamProps,
  FacebookTokenProps,
  GetCommentsParams,
  GetSlideParams,
  GetStreamParams,
  GetStreamsByIdsParams,
  MoveStreamToWorkspaceParams,
  PauseStreamRequest,
  PostCommentParams,
  PresentationSlide,
  RecordingFolderStreamProps,
  RemoveGuestDestinationsParams,
  RemoveSocialDestinationRequest,
  ResumeStreamRequest,
  RtmpNetworkRequest,
  StartStreamRequest,
  StopStreamRequest,
  StreamListFilterParams,
  ToggleDestinationsMap,
  TwitchCategory,
  UpdateStreamResolution,
  UpdateViewsWebinar,
  UploadFilePresentationProps,
  UploadGoogleFileRequest,
  WebinarCommentParams
} from './types';

export const updateStreamResolution = (request: Request) => ({ streamId, width, height }: UpdateStreamResolution) =>
  request<string>({
    url: '/stream/update-resolution',
    method: 'POST',
    params: { streamId },
    data: { width, height }
  });

/*
export const transformWebinarData = (stream: LiveStream) => {
  if (stream.meta.webinarId) {
    stream.destinations ||= [];
    stream.destinations.splice(0, 0, {
      video_id: 'webinar',
      destination_id: 'webinar',
      network: 'RTMP' as StreamingNetwork,
      state: stream.state,
      response: {
        live_link: `${isProd? WAVE_PROD: WAVE_TEST}webinar/${stream.meta.webinarId}`,
        stats: stream.meta.webinarStats
      } as DestinationResponse
    } as LiveStreamDestination) 
  }
  return stream;
}
*/

export const getStream = (request: Request) => async ({ streamId, token, workspace }: GetStreamParams) => {
  const stream = await request<LiveStream>({
    url: '/stream',
    headers: { ...(token && { [SECURE_TOKEN_HEADER_NAME]: token }) },
    params: { workspace, streamId }
  });

  return stream; // transformWebinarData(stream);
};

export const updateViewsWebinar = (request: Request) => ({ streamId, views }: UpdateViewsWebinar) =>
  request<string>({
    url: '/update-views-webinar',
    method: 'POST',
    params: { streamId, views }
  });

export const getStreamsByIds = (request: Request) => ({ streamIds, token, workspace }: GetStreamsByIdsParams) => {
  return request<LiveStream[]>({
    url: '/streams',
    headers: {
      ...(token && { [SECURE_TOKEN_HEADER_NAME]: token })
    },
    params: { workspace, streamIds: streamIds.join() }
  });
};

export const getComments = (request: Request) => async ({ streamId, token, after }: GetCommentsParams) => {
  const config = {
    url: '/comments-stream',
    params: { after, streamId },
    headers: {
      ...(token && { [SECURE_TOKEN_HEADER_NAME]: token }),
      accept: 'application/json'
    },
    onlyData: false
  };

  const res = (await (request<RoomComment[]>(config) as unknown)) as AxiosResponse<RoomComment[]>;

  const _after = res.headers['anm-time'] - 10 * 1000;

  return { comments: res.data, after: _after };
};

export const postComment = (request: Request) => {
  return async ({ streamId, destinationIds, data, token }: PostCommentParams) => {
    return await request<string>({
      method: 'POST',
      url: '/post-comment',
      params: { destinationIds, streamId },
      headers: {
        ...(token && { [SECURE_TOKEN_HEADER_NAME]: token }),
        accept: 'application/json'
      },
      data
    });
  };
};

export const postWebinarComment = (request: Request) => {
  return async ({ streamId, token, ...data }: WebinarCommentParams) => {
    return await request<string>({
      method: 'POST',
      url: '/post-comment-webinar',
      params: { streamId },
      headers: {
        ...(token && { [SECURE_TOKEN_HEADER_NAME]: token }),
        accept: 'application/json'
      },
      data
    });
  };
};

export const getStreams = (request: Request) => (params: StreamListFilterParams) =>
  request<LiveStream[]>({
    params,
    url: '/list',
    headers: {
      'Cache-Control': 'no-cache'
    }
  });

export const removeStream = (request: Request) => ({
  workspace,
  streamId,
  destinationIds,
  hasWebinar
}: DeleteStreamProps) =>
  request<DestinationList>({
    url: '/stream',
    method: 'DELETE',
    params: {
      streamId,
      workspace,
      ...((destinationIds.length > 0 || hasWebinar) && { simple: true }),
      destinationIds: destinationIds.toString()
    }
  });

export const getDestinations = (request: Request) => (query = '') =>
  request<DestinationList>({
    url: `/social${query}`
  });

export const socialCheck = (request: Request) => () =>
  request<DestinationList>({
    url: `/social-check`
  });

export const removeSocialDestination = (request: Request) => ({ destinationId }: RemoveSocialDestinationRequest) =>
  request({
    url: '/remove-social-destination',
    method: 'DELETE',
    params: {
      destinationId
      // unschedule: true // if need to delete destination with scheduled streams
    }
  });

export const toggleSocialDestinations = (request: Request) => (map: ToggleDestinationsMap) =>
  request({
    url: '/toggle-social-destinations',
    method: 'POST',
    data: map
  });

export const saveFacebookToken = (request: Request) => (tokenProps: FacebookTokenProps) =>
  request({
    url: '/social/facebook/save-token',
    method: 'POST',
    data: tokenProps
  });

export const createStream = (request: Request) => (data: CreateStreamRequest) =>
  request<LiveStream>({
    data,
    method: 'POST',
    url: '/stream'
  });

export const moveStreamToTeam = (request: Request) => ({ streamId }: MoveStreamToWorkspaceParams) =>
  request<LiveStream>({
    params: { streamId, workspace: 'team' },
    method: 'POST',
    url: '/stream/change-workspace'
  });

export const moveStreamToUser = (request: Request) => ({ streamId }: MoveStreamToWorkspaceParams) =>
  request<LiveStream>({
    params: { streamId, workspace: 'user' },
    method: 'POST',
    url: 'stream/change-workspace'
  });

const retry = createRetry<string>({
  maxRetries: 5,
  calcDelay: (_, currentDelay) => (currentDelay || 0.5) + currentDelay
});
export const startStream = (request: Request) => ({ streamId, token }: StartStreamRequest) =>
  retry(() =>
    request({
      params: { streamId },
      method: 'POST',
      headers: {
        ...(token && { [SECURE_TOKEN_HEADER_NAME]: token })
      },
      url: '/stream-start/now'
    })
  );

export const stopStream = (request: Request) => ({ streamId, token }: StopStreamRequest) =>
  retry(() =>
    request({
      params: { streamId },
      method: 'POST',
      headers: {
        ...(token && { [SECURE_TOKEN_HEADER_NAME]: token })
      },
      url: '/stream-stop'
    })
  );

export const resumeStream = (request: Request) => ({ streamId }: ResumeStreamRequest) =>
  request<string>({
    params: { streamId },
    method: 'POST',
    url: '/stream-resume'
  });

export const pauseStream = (request: Request) => ({ streamId }: PauseStreamRequest) =>
  request<string>({
    params: { streamId },
    method: 'POST',
    url: '/stream-pause'
  });

export const addRTMPDestination = (request: Request) => (data: RtmpNetworkRequest) =>
  request<LiveStream>({
    data,
    url: '/rtmp',
    method: 'POST'
  });

export const updateStream = (request: Request) => ({ streamId, token, ...data }: EditStreamProps) =>
  request<LiveStream>({
    data,
    url: '/update-stream',
    method: 'POST',
    headers: {
      ...(token && { [SECURE_TOKEN_HEADER_NAME]: token })
    },
    params: { streamId }
  });

export const updateStreamGuest = (request: Request) => ({ streamId, ...data }: EditStreamProps) =>
  request<LiveStream>({
    data,
    url: '/update-stream-guest',
    method: 'POST',
    params: { streamId }
  });

export const editRecordingMeta = (request: Request) => ({ streamId, ...data }: EditStreamProps) =>
  request<LiveStream>({
    data,
    url: '/edit-meta-record',
    method: 'POST',
    params: { streamId }
  });

export const updateRecordingFolder = (request: Request) => ({
  streamId,
  folderId,
  token
}: RecordingFolderStreamProps) =>
  request<LiveStream>({
    url: '/stream/update-recording-folder',
    method: 'POST',
    headers: {
      ...(token && { [SECURE_TOKEN_HEADER_NAME]: token })
    },
    params: { streamId, folderId }
  });

export const changeStreamKind = (request: Request) => (params: ChangeKindParams) =>
  request<string>({
    params,
    url: '/change-kind',
    method: 'POST'
  });

export const checkUrl = (request: Request) => (url: string) =>
  request<string>({
    url,
    method: 'HEAD',
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  });

export const duplicateStream = (request: Request) => ({ baseUrl, roomIdTo, roomIdFrom }: DuplicateRequestParams) =>
  request<string>({
    method: 'POST',
    baseURL: baseUrl,
    url: `rooms/copy/${roomIdFrom}/${roomIdTo}`
  });

export const getTwitchCategories = (request: Request) => (query: string, baseUrl: string) =>
  request<TwitchCategory[]>({
    params: { query },
    url: 'twitch/category/search',
    baseURL: baseUrl
  });

export const getGuestDestinations = (request: Request) => (streamId: string) =>
  request<Record<string, DestinationObject>>({
    url: '/guest-destinations',
    params: { streamId }
  });

export const getDestinationAvatar = (request: Request) => (streamId: string, destinationId: string) =>
  request<string>({
    url: '/dest-avatar',
    params: { streamId, destinationId }
  });

export const removeGuestDestinations = (request: Request) => (params: RemoveGuestDestinationsParams) =>
  request<string>({
    params,
    method: 'POST',
    url: '/remove-guest-destinations'
  });

export const getPublicStream = (request: Request) => (streamId: string) =>
  request<LiveStream>({ method: 'GET', url: '/public-stream', params: { streamId } });

export const uploadPresentationFile = (request: Request) => ({
  file,
  baseURL,
  cancelToken,
  onUploadProgress
}: UploadFilePresentationProps) => {
  const data = new FormData();
  data.append('file', file);

  return request<string>({
    data,
    baseURL,
    cancelToken,
    method: 'POST',
    url: `/slides/upload/${getAuthToken()}`,
    onUploadProgress
  });
};

export const getSlidesList = (request: Request) => (baseURL: string) =>
  request<PresentationSlide[]>({ method: 'GET', baseURL, url: '/slides/list' });

export const getSlide = (request: Request) => ({ id, baseURL }: GetSlideParams) =>
  request<PresentationSlide>({ method: 'GET', baseURL, url: `/slides/${id}` });

export const deleteSlide = (request: Request) => ({ id, baseURL }: GetSlideParams) =>
  request<'OK'>({ method: 'DELETE', baseURL, url: `/slides/${id}` });

export const uploadGoogleFile = (request: Request) => ({
  baseURL,
  cancelToken,
  onUploadProgress,
  ...data
}: UploadGoogleFileRequest) =>
  request<string>({ data, method: 'POST', baseURL, cancelToken, url: `/slides/picker`, onUploadProgress });
