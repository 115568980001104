import { Request } from '../../types';

import {
  CreateFolderParams,
  DeleteFolderParams,
  Folder,
  GetByIdParams,
  GetByPathParams,
  GetRootFoldersParams,
  GetTreeParams,
  MoveFolderParams,
  RenameFolderParams
} from './types';

export * from './types';

const defaultParams = {
  product: 'WAVE'
};

const extendParams = <T extends {}>(params: T = {} as T) => ({
  ...defaultParams,
  ...params
});

export const create = (request: Request) => (params: CreateFolderParams) =>
  request<Folder>({
    params: extendParams(params),
    method: 'POST',
    url: 'folder'
  });

export const getById = (request: Request) => (params: GetByIdParams) =>
  request<Folder>({
    params: extendParams(params),
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0 '
    },
    url: 'folder/fetch'
  });

export const getRoot = (request: Request) => ({ workspace }: GetRootFoldersParams) =>
  request<Folder>({
    params: extendParams({ workspace }),
    method: 'GET',
    url: 'folder/root'
  });

export const getByPath = (request: Request) => ({ path, workspace }: GetByPathParams) =>
  request<Folder>({
    params: extendParams({ workspace, path: `/${path || ''}` }),
    method: 'GET',
    url: 'folder/fetch'
  });

export const getTree = (request: Request) => ({ workspace }: GetTreeParams) =>
  request<Folder>({
    params: extendParams({ workspace }),
    method: 'GET',
    url: 'folder/tree'
  });

export const move = (request: Request) => (params: MoveFolderParams) =>
  request<Folder>({
    params: extendParams(params),
    method: 'PUT',
    url: 'folder/move'
  });

export const rename = (request: Request) => (params: RenameFolderParams) =>
  request<Folder>({
    params: extendParams(params),
    method: 'PUT',
    url: 'folder/rename'
  });

export const deleteFolder = (request: Request) => (params: DeleteFolderParams) =>
  request<Folder>({
    params: extendParams(params),
    method: 'DELETE',
    url: 'folder/delete'
  });
