import { Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import { changePasswordAsync } from '../actions';
import { UserActions } from '../types';

import { initialUserState } from '.';

const changePasswordReducer: Reducer<typeof initialUserState, UserActions> = (
  state = initialUserState,
  action
) => {
  switch (action.type) {
    case getType(changePasswordAsync.request): {
      const params = action.payload;

      return {
        ...state,
        changePassword: { params, isError: false, isPending: true }
      };
    }
    case getType(changePasswordAsync.success): {
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          isError: false,
          isPending: false
        }
      };
    }
    case getType(changePasswordAsync.failure): {
      const error = action.payload;

      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          error,
          isError: true,
          isPending: false
        }
      };
    }
    default:
      return state;
  }
};

export default changePasswordReducer;
