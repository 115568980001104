import { DeepPartial } from 'helpers';

import { Config } from './types';

const configs: DeepPartial<Config> = {
  nextDevMode: true,
  urls: {
    wave: 'http://localhost:3005/',
    studio: 'http://localhost:3000/studio/',
    animatronRoot: 'http://localhost:3000/'
  },
  analytics: {
    google: {
      enable: false
    },
    facebook: {
      enable: false
    },
    mixpanel: {
      enable: false
    },
    fullstory: {
      enable: false
    },
    hotjar: {
      enable: false
    }
  },
  socialNetworks: {
    google: {
      apiKey:
        '603970023355-halb0td31nicpnj8gp5rconc173d58jo.apps.googleusercontent.com'
    },
    facebook: {
      apiKey: '867597719991913'
    }
  }
};

export default configs;
