import asyncEntityHandlers from '@anm/helpers/redux/asyncEntityHandlers';
import { PersistModule } from '@anm/helpers/redux/persistState';
import reduceFactory from '@anm/helpers/redux/reduceFactory';
import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import {
  changePasswordAsync,
  cleanStorageStats,
  cleanUser,
  confirmEmailAsync,
  decrementEmbedStats,
  deleteAccountAsync,
  fetchEmbedStatsAsync,
  fetchHostingStatsAsync,
  fetchStorageStatsAsync,
  fetchTextToSpeechStatsAsync,
  fetchTranscribeStatsAsync,
  getUserAsync,
  incrementEmbedStats,
  resendConfirmEmailAsync,
  setIsUserBlockedOnHosting,
  updateAsync
} from '../actions';
import { UserActions, UserState } from '../types';

import changePasswordReducer from './changePasswordReducer';
import deleteAccountReducer from './deleteAccountReducer';
import embedStatsReducer from './embedsStatsReducer';
import hostingStatsReducer from './hostingStatsReducer';
import resendConfirmEmailReducer from './resendConfirmEmailReducer';
import storageStatsReducer from './storageStatsReducer';
import textToSpeechStatsReducer from './textToSpeechStatsReducer';
import transcribeStatsReducer from './transcribeStatsReducer';
import updateProfileReducer from './updateProfileReducer';

const defaultPendingState = { isError: false, isPending: false };

export const initialUserState: UserState = {
  profile: null,
  updateParams: null,
  isResendEmailPending: false,
  ...defaultPendingState,
  deleteAccount: {
    isDeleted: false,
    ...defaultPendingState
  },
  confirmEmail: {
    params: null,
    ...defaultPendingState
  },
  changePassword: {
    params: null,
    ...defaultPendingState
  },
  embedStats: defaultPendingState,
  hostingStats: defaultPendingState,
  storageStats: defaultPendingState,
  transcribeStats: defaultPendingState,
  textToSpeechStats: defaultPendingState
};

export const userPersistConfig: PersistModule = {
  path: 'user',
  blackList: ['isPending', 'embedStats', 'deleteAccount', 'changePassword', 'isResendEmailPending', 'confirmEmail']
};

const userReducer: Reducer<typeof initialUserState, UserActions> = (state = initialUserState, action) => {
  switch (action.type) {
    case getType(cleanUser): {
      const { deleteAccount, ...exceptDeleteAccount } = initialUserState;

      return {
        ...state,
        ...exceptDeleteAccount
      };
    }

    case getType(updateAsync.request):
    case getType(updateAsync.success):
    case getType(updateAsync.failure):
    case getType(getUserAsync.request):
    case getType(getUserAsync.success):
    case getType(getUserAsync.failure):
      return updateProfileReducer(state, action);

    case getType(resendConfirmEmailAsync.request):
    case getType(resendConfirmEmailAsync.success):
    case getType(resendConfirmEmailAsync.failure):
      return resendConfirmEmailReducer(state, action);

    case getType(deleteAccountAsync.request):
    case getType(deleteAccountAsync.success):
    case getType(deleteAccountAsync.failure):
      return deleteAccountReducer(state, action);

    case getType(changePasswordAsync.request):
    case getType(changePasswordAsync.success):
    case getType(changePasswordAsync.failure):
      return changePasswordReducer(state, action);

    case getType(fetchEmbedStatsAsync.request):
    case getType(fetchEmbedStatsAsync.success):
    case getType(fetchEmbedStatsAsync.failure):
    case getType(incrementEmbedStats):
    case getType(decrementEmbedStats):
      return embedStatsReducer(state, action);

    case getType(fetchHostingStatsAsync.request):
    case getType(fetchHostingStatsAsync.success):
    case getType(fetchHostingStatsAsync.failure):
      return hostingStatsReducer(state, action);

    case getType(fetchStorageStatsAsync.request):
    case getType(fetchStorageStatsAsync.success):
    case getType(fetchStorageStatsAsync.failure):
    case getType(cleanStorageStats):
      return storageStatsReducer(state, action);

    case getType(fetchTranscribeStatsAsync.request):
    case getType(fetchTranscribeStatsAsync.success):
    case getType(fetchTranscribeStatsAsync.failure):
      return transcribeStatsReducer(state, action);

    case getType(fetchTextToSpeechStatsAsync.request):
    case getType(fetchTextToSpeechStatsAsync.success):
    case getType(fetchTextToSpeechStatsAsync.failure):
      return textToSpeechStatsReducer(state, action);

    case getType(setIsUserBlockedOnHosting):
      return {
        ...state,
        isBlockedOnHosting: action.payload
      };

    default:
      return reduceFactory(initialUserState, {
        ...asyncEntityHandlers<UserState>(confirmEmailAsync, 'confirmEmail')
      })(state, action);
  }
};

export default userReducer;
