import { ApiError } from '@anm/api';
import { AuthUserResponse, LoginData, OAuthData, SignUpData } from '@anm/api/modules/auth';
import { createAction, createAsyncAction } from '@anm/helpers/saga/typesafe-actions';
import { Omit } from 'recompose';
import { User } from 'user';

import { PostTokenData } from '../../../helpers/socialAuth/tokenMessage';

export type AuthResponse = Omit<AuthUserResponse, 'userProfile'> & {
  userProfile?: User;
};

export type LogoutWithRedirectProps = { route: string; params: { [key in string]: string } };

export const startSessionWatcher = createAction('wave.video/auth/START_SESSION_WATCHER')();
export const stopSessionWatcher = createAction('wave.video/auth/STOP_SESSION_WATCHER')();

export const resetAuthState = createAction('wave.video/auth/RESET_AUTH_STATE')();
export const logoutWithRedirect = createAction('wave.video/auth/LOGOUT_WITH_REDIRECT')<
  LogoutWithRedirectProps | undefined
>();
export const logout = createAction('wave.video/auth/LOGOUT')();
export const logoutAsync = createAsyncAction(
  'wave.video/auth/LOGOUT_REQUEST',
  'wave.video/auth/LOGOUT_SUCCESS',
  'wave.video/auth/LOGOUT_FAILURE'
)<User, User, ApiError>();

export const signup = createAction('wave.video/auth/SIGNUP')<SignUpData>();

export const signupAnon = createAction('wave.video/auth/SIGNUP_ANON')();
export const signupAnonAsync = createAsyncAction(
  'wave.video/auth/SIGNUP_ANON_REQUEST',
  'wave.video/auth/SIGNUP_ANON_SUCCESS',
  'wave.video/auth/SIGNUP_ANON_FAILURE'
)<null, AuthResponse, ApiError>();

export const login = createAction('wave.video/auth/LOGIN')<Omit<LoginData, 'product'>>();
export const loginOAuth = createAction('wave.video/auth/LOGIN_OAUTH')<OAuthData>();

export const sessionError = createAction('wave.video/auth/SESSION_ERROR')<ApiError>();

export const postOAuthToken = createAction('wave.video/auth/POST_OAUTH_TOKEN')<PostTokenData>();

export const loginWithToken = createAction('wave.video/auth/LOGIN_WITH_TOKEN')<string>();

export const authAsync = createAsyncAction(
  'wave.video/auth/AUTH_REQUEST',
  'wave.video/auth/AUTH_SUCCESS',
  'wave.video/auth/AUTH_FAILURE'
)<{ provider: string }, AuthResponse, ApiError>();

export const authFinished = createAction('wave.video/auth/FINISHED')<AuthResponse>();
