import { ErrorGoHomeProps } from '@anm/components/errors/ErrorGoHome';
export type StatusCode = 400 | 403 | 404 | 440 | 460 | 498 | 500;

const getErrorByStatusCode = (statusCode: StatusCode) => {
  const errors: { [key in StatusCode]: ErrorGoHomeProps } = {
    400: {
      title: '',
      description: 'You have not permission to access this resource'
    },
    404: {
      title: 'Oh no, bad luck!',
      description:
        'Looks like the page you were looking for has been removed. Just in case, please make sure to check your spelling.'
    },
    403: {
      title: '',
      description: 'Your session has been expired. Please re-login again.'
    },
    440: {
      title: '',
      description: 'You have reached maximum allowed number of concurrent sessions.'
    },
    460: {
      title: '',
      description: 'Please re-login again.'
    },
    498: {
      title: 'Expired',
      description: 'The link was expired or invalid. Please try again.'
    },
    500: {
      title: 'Oops, something went wrong!',
      description:
        'We are already on it. Please try again later or contact us at <a href="mailto:support@wave.video">support@wave.video</a>'
    }
  };

  return errors[statusCode] || errors[500];
};

export default getErrorByStatusCode;
