import { Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import { clearStocks, fetchStocksAsync } from '../actions';
import { StocksItemsState, StockActions } from '../types';

export const initialStockItemsState: StocksItemsState = {
  list: [],
  count: 0,
  limit: 30,
  offset: 0,
  params: null,
  isError: false,
  isPending: false
};

const stocksReducer: Reducer<StocksItemsState, StockActions> = (
  state = initialStockItemsState,
  action
) => {
  switch (action.type) {
    case getType(clearStocks): {
      return initialStockItemsState;
    }
    case getType(fetchStocksAsync.request): {
      return {
        ...state,
        params: action.payload,
        isError: false,
        isPending: true
      };
    }
    case getType(fetchStocksAsync.success): {
      const { list: prevList, offset, limit } = state;
      const { count, items: newList = [] } = action.payload;

      return {
        ...state,
        count,
        list: [...prevList, ...newList],
        offset: offset + limit,
        isPending: false
      };
    }
    case getType(fetchStocksAsync.failure): {
      const { payload: newError } = action;
      return {
        ...state,
        isError: true,
        error: newError,
        isPending: false
      };
    }
    default:
      return state;
  }
};

export default stocksReducer;
