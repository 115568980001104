import { Logger } from '@anm/helpers/Debugger';
import sleep from '@anm/helpers/sleep';
import { isObject } from 'lodash/fp';

const logger = new Logger('retry');

export type RetryConfig = {
  delay: number;
  maxRetries: number;
  calcDelay: (startDelay: number, currentDelay: number) => number;
};

const createRetry = <T>(config: Partial<RetryConfig>) => (request: () => Promise<T>) =>
  new Promise<T>(async (resolve, reject) => {
    const defaultConfig = { delay: 1, maxRetries: 3, calcDelay: v => v } as RetryConfig;

    const { maxRetries, delay: startDelay, calcDelay } = isObject(config)
      ? { ...defaultConfig, ...config }
      : defaultConfig;
    let retries = 0;
    let currentDelay = 0;

    let lastError: Error | undefined | any;

    while (retries < maxRetries) {
      try {
        resolve(await request());
        return;
      } catch (e) {
        const error = e as any;
        lastError = error;
        const status = error?.response?.status || 500;
        logger.warn(`Error Status: ${status}`, error);

        currentDelay = calcDelay(startDelay, currentDelay);

        await sleep(currentDelay * 1000);

        retries++;
      }
    }
    logger.error(`Too many request retries.`, lastError);
    reject(lastError);
  });

export default createRetry;
