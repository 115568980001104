import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import { getUserAsync, updateAsync } from '../actions';
import { UserActions } from '../types';

import { initialUserState } from '.';

const updateProfileReducer: Reducer<typeof initialUserState, UserActions> = (state = initialUserState, action) => {
  switch (action.type) {
    case getType(getUserAsync.request):
    case getType(updateAsync.request): {
      return {
        ...state,
        error: null,
        isError: false,
        isPending: true,
        updateParams: action.payload
      };
    }
    case getType(getUserAsync.success): {
      const user = action.payload;

      return {
        ...state,
        profile: user,
        isError: false,
        isPending: false,
        deleteAccount: initialUserState.deleteAccount
      };
    }
    case getType(updateAsync.success): {
      const user = action.payload;

      return {
        ...state,
        profile: {
          ...state.profile,
          ...user
        },
        isError: false,
        isPending: false,
        deleteAccount: initialUserState.deleteAccount
      };
    }
    case getType(getUserAsync.failure):
    case getType(updateAsync.failure): {
      const error = action.payload;
      return {
        ...state,
        error,
        isError: true,
        isPending: false
      };
    }
    default:
      return state;
  }
};

export default updateProfileReducer;
