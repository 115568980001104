import { Request } from '../../types';

import { DomainItem, EditDomainProps } from './types';

export * from './types';

export const getUserDomains = (request: Request) => (baseURL: string) =>
  request<DomainItem[]>({
    baseURL,
    method: 'GET',
    url: `/list`
  });

export const generateCert = (request: Request) => ({ host, baseURL }: EditDomainProps) =>
  request<string>({
    baseURL,
    method: 'POST',
    url: `/generate?host=${host}`
  });

export const removeCert = (request: Request) => ({ host, baseURL }: EditDomainProps) =>
  request<string>({
    baseURL,
    method: 'POST',
    url: `/remove?host=${host}`
  });

export const verifyHost = (request: Request) => ({ host, baseURL }: EditDomainProps) =>
  request<string>({
    baseURL,
    method: 'GET',
    url: `/verify?host=${host}`
  });
