import { combineReducers } from 'redux';

import stockItemsReducer from './stockItemsReducer';
import youtubeChannelsReducer from './youtubeChannelsReducer';
import youtubeDisputeClaimReducer from './youtubeDisputeClaimReducer';

export default combineReducers({
  stockItems: stockItemsReducer,
  youtubeChannelChecker: youtubeChannelsReducer,
  youtubeDisputeClaim: youtubeDisputeClaimReducer
});
