import { useEffect, useRef } from 'react';

const useDependPendingCallback = (isPending: boolean, callback: () => void) => {
  const prevPendingRef = useRef(isPending);

  useEffect(() => {
    prevPendingRef.current && !isPending && callback();
    prevPendingRef.current = isPending;
  }, [callback, isPending]);
};

export default useDependPendingCallback;
