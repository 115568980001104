import { CUSTOMIZE_WEBINAR_ROUTE, VIDEO_TEMPLATE_ROUTE } from '@anm/constants/routes';
import Routes from 'next-routes';

export type Route = {
  name: string;
  page: string;
  keys: string[];
  regex: RegExp;
  pattern: string;
  keyNames: string[];
  toPath: Function;
};

const routes = require('next-routes')() as Routes;

routes.add('404', '/404', '_error');
routes.add('error', '/error', '_error');
routes.add('user-error', '/user/error', 'user/error');
routes.add('studio-user-error', '/studio/user/error', 'studio/user/error');

routes.add('social-auth-callback', '/callback/:provider', 'social-auth-callback');

routes.add('login', '/login');
routes.add('studio-login', '/studio/login', 'studio/login');
routes.add('editor-login', '/editor/login', '/editor/login');
routes.add('editor-signup', '/editor/signup', '/editor/signup');
routes.add('live-login', '/lives/login', '/lives/login');
routes.add('facebook-group-auth', '/lives/facebook/:version?', '/lives/facebook');
routes.add('login-embed', '/editor/embed/login', '/editor/embed/login');
routes.add('signup-embed', '/editor/embed/signup', '/editor/embed/signup');
routes.add('signup', '/signup');
routes.add('studio-signup', '/studio/signup', 'studio/signup');
routes.add('recovery', '/recovery', 'recovery-request');
routes.add('reset', '/recovery/:hash', 'recovery-reset');

routes.add('studio-recovery', '/studio/recovery', 'studio/recovery-request');
routes.add('studio-reset', '/studio/recovery/:hash', 'studio/recovery-reset');

routes.add('appsumo', '/appsumo/:code');

routes.add('privacy', '/privacy');
routes.add('cookie-policy', '/cookie-policy');
routes.add('terms-of-service', '/terms-of-service');
routes.add('error-non-supported-browser', '/error-non-supported-browser');
routes.add('error-mobile', '/error-mobile');
routes.add('studio-privacy', '/studio/privacy', 'studio/privacy');
routes.add('studio-terms-of-service', '/studio/terms-of-service', 'studio/terms-of-service');

routes.add('confirmation-email', '/confirmation/email/:hash', 'confirmation/email');

routes.add('pricing', '/pricing');
routes.add('pricing-upgrade', '/pricing/upgrade', 'pricing');

routes.add('video-templates', '/video-templates');
routes.add(VIDEO_TEMPLATE_ROUTE, '/video-templates/:pageName?/:pageNumber?', 'video-templates');

routes.add('video-templates-embed', '/editor/embed/video-templates', '/editor/embed/video-templates');

routes.add('calendar', '/calendar/:weekDate?');
routes.add('wave-event', '/calendar/:weekDate/events/:eventId');

routes.add('studio-confirmation-email', '/studio/confirmation/email/:hash', 'studio/confirmation/email');

routes.add('video', '/video/:projectId/:tab');
routes.add('video-root', '/video/:projectId', 'video');

routes.add('my-projects', '/videos', 'my-projects');
routes.add('lives', '/lives/:tab?', 'lives');
routes.add('root-uploads', '/uploads', 'uploads');
routes.add('uploads', '/uploads/:folderPath*', 'uploads');
routes.add('recordings', '/recordings/:tab?', 'recordings');
routes.add('account-tab', '/account/:tab', 'account');
routes.add('account', '/account', 'account');
routes.add('subscription', '/subscription', 'subscription');

routes.add('my-projects-folder', '/videos/:folderPath*', 'my-projects');

routes.add('manage-team', '/account/manage-team', 'account');
routes.add('team-invite', '/team/invite/:hash', 'team/invite');
routes.add('profile', '/account/profile', 'account');
routes.add('youtube-whitelist', '/account/youtube-whitelist', 'account');
routes.add('youtube-streaming', '/how-to-enable-live-streaming-on-youtube', 'enable-youtube-stream');
routes.add('add-youtube-channel', '/how-to-add-youtube-channel', 'add-youtube-channel');
routes.add('facebook-streaming', '/how-to-enable-live-streaming-on-facebook', 'enable-facebook-stream');
routes.add('linkedin-streaming', '/how-to-enable-live-streaming-on-linkedin', 'enable-linkedin-stream');
routes.add('add-destination-callback', '/add-destination-callback', 'add-destination-callback');

routes.add('webinar', `/${CUSTOMIZE_WEBINAR_ROUTE}/:webinarId/:tab?`);

export default routes;
