import { UserSubscriptionDetails } from '@anm/api/modules/user';
import compose from 'lodash/fp/compose';
import { createSelector } from 'reselect';
import { User } from 'user';

import { ApplicationState } from '../../types';
const WAVE_SUBSCRIPTION_INDEX = 0;

const isUser = (testValue: string) =>
  compose((subscriptionName: string) => new RegExp(testValue).test(subscriptionName), selectWaveUserSubscriptionName);

const getWaveSubscription = (subscriptions: UserSubscriptionDetails[]) =>
  subscriptions.filter(s => s.product === 'WAVE')[WAVE_SUBSCRIPTION_INDEX];

export const selectSubscriptionDetails = ({ user }: ApplicationState) => user.profile?.subscriptionDetails;

export const selectUserPending = ({ user }: ApplicationState) => user.isPending;

export const selectUserConfirmEmailPending = ({ user }: ApplicationState) => user.confirmEmail.isPending;
export const selectUserConfirmEmailError = ({ user }: ApplicationState) => user.confirmEmail.error;
export const selectUserConfirmEmailIsError = ({ user }: ApplicationState) => user.confirmEmail.isError;

export const selectUserError = ({ user }: ApplicationState) => user.error;
export const selectChangePasswordError = ({ user }: ApplicationState) => user.changePassword.error?.message;

export const selectChangePasswordPending = ({ user }: ApplicationState) => user.changePassword.isPending;

export const selectWaveSubscription = createSelector(
  selectSubscriptionDetails,
  subscriptions => subscriptions && getWaveSubscription(subscriptions)
);

export const selectTeamId = ({ user }: ApplicationState) => user.profile?.teamId;
export const selectTeamRole = ({ user }: ApplicationState) => user.profile?.teamRole;
export const selectTeamPermissions = ({ user }: ApplicationState) => user.profile?.teamMemberPermissions;
export const selectMaxTeamUsers = createSelector(
  selectWaveSubscription,
  subscription => subscription?.features.Teams?.maxUsers
);
export const selectTeamAccess = createSelector(
  selectTeamId,
  selectMaxTeamUsers,
  (teamId, maxUsers) => !!(teamId && maxUsers && maxUsers > 1)
);

export const selectUserStripeStatus = createSelector(selectWaveSubscription, subscription => {
  const { hasStripeUser, hasStripeSubscription } = subscription || {};
  return hasStripeUser || hasStripeSubscription;
});

export const selectStudioSubscription = createSelector(
  selectSubscriptionDetails,
  subscriptions => subscriptions?.filter(({ product }) => product === 'STUDIO')[0]
);

export const selectFreeUser = createSelector(
  selectWaveSubscription,
  selectStudioSubscription,
  (waveSubscription, studioSubscription) => waveSubscription?.name === 'WaveFree' && studioSubscription?.name === 'Free'
);

export const selectWaveFreeUser = createSelector(
  selectWaveSubscription,
  waveSubscription => waveSubscription?.name === 'WaveFree'
);

export const selectUser = ({ user }: ApplicationState) => user;
export const selectUserProfile = ({ user }: ApplicationState) => user.profile;
export const selectUpdateUserParams = ({ user }: ApplicationState) => user.updateParams;
export const selectHasProfile = createSelector(
  selectUserProfile,
  selectUserPending,
  (profile, isPending) => !!profile && !isPending
);
export const selectUserBillingAddress = createSelector(selectUserProfile, profile => profile?.meta.billingAddress);
export const selectUserCreated = createSelector(selectUserProfile, profile => profile?.created);

export const selectUserEmail = ({ user }: ApplicationState) => user.profile?.email;
export const selectResendEmailPending = ({ user }: ApplicationState) => user.isResendEmailPending;

export const selectUserId = ({ user }: ApplicationState) => user.profile?.userId;
export const selectUsername = ({ user }: ApplicationState) => user.profile?.username;

export const selectUserEmbeds = ({ user }: ApplicationState) => user.embedStats?.data;

export const selectUserEmbedStats = ({ user }: ApplicationState) => user.embedStats;

export const selectUserStorageStats = ({ user }: ApplicationState) => user.storageStats;

export const selectUserHostingStats = ({ user }: ApplicationState) => user.hostingStats;

export const selectUserTranscribeStats = ({ user }: ApplicationState) => user.transcribeStats;

export const selectUserTextToSpeechStats = ({ user }: ApplicationState) => user.textToSpeechStats;

export const selectUserExtraEmbeds = createSelector(selectWaveSubscription, waveSubscription => {
  if (!waveSubscription) return;

  const { extraEmbedPrice, canPurchaseEmbeds } = waveSubscription.features.Hosting;

  return { extraEmbedPrice, canPurchaseEmbeds };
});

export const selectWaveUserSubscriptionName = (user: User | null) => {
  if (!user) return;

  const { name } = getWaveSubscription(user.subscriptionDetails);
  return name;
};

export const isUserPro = isUser('WavePro') || isUser('WavePro2') || isUser('WavePro3');
export const isUserFree = isUser('WaveFree');
export const isUserGuest = isUser('Guest');
export const isUserStaff = isUser('Jedi|Staff');
export const isUserBusiness = isUser('WaveBusiness');
export const isUserAgency =
  isUser('WaveAgency') || isUser('WaveAgency2') || isUser('WaveAgency3') || isUser('WaveAgency4');
export const isUserCreator = isUser('WaveCreator');
export const isUserCorporate = isUser('WaveCorporate');
export const isUserPremium = (user: User | null) =>
  [isUserPro, isUserStaff, isUserAgency, isUserCorporate].reduce((acc, check) => acc || check(user), false);
export const isSemrushUser = createSelector(selectUserProfile, profile => profile?.meta.entry === 'SEMRUSH');

export const selectUserHostingSettings = ({ user: { profile } }: ApplicationState) => {
  if (!profile) return;

  const waveSubscription = getWaveSubscription(profile.subscriptionDetails);

  return waveSubscription.features.Hosting.settings;
};

export const selectStocksFeature = (state: ApplicationState) => selectWaveSubscription(state)?.features.Stocks;
export const selectHostingFeature = (state: ApplicationState) => selectWaveSubscription(state)?.features.Hosting;

export const selectText2VideoFeature = (state: ApplicationState) => selectWaveSubscription(state)?.features.Text2Video;

export const selectMaxText2VideoProjectDuration = createSelector(
  selectText2VideoFeature,
  features => features?.maxProjectDurationMin
);

export const selectCanPurchaseStorage = createSelector(selectHostingFeature, features => features?.canPurchaseStorage);

export const selectUserLandingsSettings = ({ user: { profile } }: ApplicationState) => {
  if (!profile) return;

  const waveSubscription = getWaveSubscription(profile.subscriptionDetails);

  return waveSubscription.features.Hosting.landings;
};

export const selectUploadVideoLimits = createSelector(selectWaveSubscription, waveSubscription => {
  if (!waveSubscription) return;

  const { maxSize, maxSeconds } = waveSubscription.features.ImportVideo;

  return { maxSize, maxSeconds };
});

export const selectUserYoutubeChannels = createSelector(
  selectUserProfile,
  profile => profile?.meta.youtubeChannels || []
);

export const selectIsStaffUser = createSelector(selectUserProfile, profile => isUserStaff(profile));

export const selectIsUserCreator = createSelector(selectUserProfile, profile => isUserCreator(profile));

export const selectIsUserFree = createSelector(selectUserProfile, profile => isUserFree(profile));

export const selectIsBusinessUser = createSelector(selectUserProfile, profile => isUserBusiness(profile));

export const selectIsUserPro = createSelector(selectUserProfile, profile => isUserPro(profile));
export const selectIsUserGuest = createSelector(selectUserProfile, profile => isUserGuest(profile));
export const selectIsUserNew = createSelector(
  selectUserProfile,
  profile => !profile || isUserFree(profile) || isUserGuest(profile)
);

export const selectIsEmailConfirmed = createSelector(selectUserProfile, profile => profile?.emailConfirmed);

const selectWaveFeatures = createSelector(selectWaveSubscription, waveSubscription => waveSubscription?.features);

export const selectIsMaxWavePlan = createSelector(
  selectWaveSubscription,
  waveSubscription => !waveSubscription?.upgrades?.length
);

export const selectMaxDestinations = createSelector(
  selectWaveFeatures,
  features => features?.Streaming.maxDestinations || 0
);
export const selectCanUseSameTypeDestinations = createSelector(
  selectWaveFeatures,
  features => !!features?.Streaming.canUseSameTypeDestinations
);

export const selectCanStreamHosted = createSelector(
  selectWaveFeatures,
  features => features?.Streaming.canStreamHosted
);

export const selectCanLiveStream = createSelector(selectWaveFeatures, features => features?.Streaming.canLiveStream);

export const selectCanRecord = createSelector(selectWaveFeatures, features => !!features?.Streaming.canRecord);

export const selectStreamMaxGuests = createSelector(selectWaveFeatures, features => features?.Streaming.maxGuests);

export const selectMaxStreamingResolution = createSelector(
  selectWaveFeatures,
  features => features?.Streaming.streamingResolution
);

export const selectUserBlockedOnHosting = ({ user }: ApplicationState) => user.isBlockedOnHosting;

export const selectCanEditBrand = createSelector(selectWaveFeatures, features => features?.Streaming.canEditBrand);

export const selectCanUserDownloadVideo = createSelector(selectWaveSubscription, waveSubscription =>
  waveSubscription?.features.ExportVideo.targets.includes('download')
);

export const selectIsWebinarsEnabled = createSelector(
  selectWaveFeatures,
  features => !!features?.Streaming.webinarsEnabled
);

export const selectCustomDomains = createSelector(
  selectWaveFeatures,
  features => features?.Hosting.customDomainsIncluded || 0
);

export const selectCanSetDomains = createSelector(selectCustomDomains, count => !!count);
