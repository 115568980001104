import { AnimatronProject } from 'types/project';

import { Request } from '../../types';

import {
  CreateProjectParams,
  DeleteProjectParams,
  DuplicateProjectParams,
  EmbedInfo,
  GetProjectProps,
  MoveProjectParams,
  MoveProjectToTeamParams,
  MoveProjectToUserParams,
  ProjectListParams,
  ProjectListResponse,
  RehashParams,
  UpdateProjectMeta
} from './types';

export * from './types';

const defaultProjectParams = {
  product: 'WAVE'
};

const extendParams = <T extends {}>(params: T = {} as T) => ({
  ...defaultProjectParams,
  ...params
});

export const getProject = (request: Request) => ({ id, baseURL, workspace }: GetProjectProps) =>
  request<AnimatronProject>({
    params: { workspace },
    method: 'GET',
    url: `project/${id}`,
    ...(baseURL && { baseURL })
  });

export const getProjectList = (request: Request) => (params: ProjectListParams) =>
  request<ProjectListResponse>({
    params,
    method: 'GET',
    url: 'project/list'
  });

export const moveProject = (request: Request) => (params: MoveProjectParams) =>
  request<{}>({
    params: extendParams(params),
    method: 'PUT',
    url: `folder/project/move`
  });

export const moveProjectToTeam = (request: Request) => (params: MoveProjectToTeamParams) =>
  request<{}>({
    params: extendParams(params),
    method: 'PUT',
    url: 'team/project/transfer/to_team'
  });

export const moveProjectToUser = (request: Request) => (params: MoveProjectToUserParams) =>
  request<{}>({
    params: extendParams(params),
    method: 'PUT',
    url: '/team/project/transfer/to_user'
  });

export const deleteProject = (request: Request) => ({ projectId, workspace }: DeleteProjectParams) =>
  request<{}>({
    method: 'DELETE',
    params: { workspace, nopreview: 1 },
    url: `project/${projectId}`
  });

export const rehash = (request: Request) => async ({ workspace, projectId }: RehashParams) =>
  request<string>({
    method: 'POST',
    params: { workspace },
    url: `/backend/render/rehash/${projectId}`
  });

export const duplicateProject = (request: Request) => async ({
  newName,
  folderId,
  workspace,
  projectId
}: DuplicateProjectParams) =>
  request<{
    id: string;
  }>({
    url: '/project-api/duplicate',
    method: 'POST',
    params: {
      folderId,
      workspace,
      name: newName,
      oldProjectId: projectId
    }
  });

export const setEmbedStatus = (request: Request) => (embedInfo: EmbedInfo) =>
  request<string>({
    url: `/project-api`,
    method: 'PUT',
    params: embedInfo
  });

export const updateProjectMeta = (request: Request) => ({ workspace, meta, projectId }: UpdateProjectMeta) =>
  request<string>({
    url: `backend/project/update/meta/${projectId}`,
    params: { workspace },
    data: meta,
    method: 'PUT'
  });

export const createProject = (request: Request) => ({
  workspace,
  hostingId,
  uploadId,
  folderId
}: CreateProjectParams) =>
  request<string>({
    url: `/project-api/upload/${uploadId}?`,
    method: 'POST',
    params: { workspace, folderId, hostingId }
  });
