import routes from '../../routes';

import { Routes } from './types';

const getRouterName = (path = location.pathname) => {
  const currentRoute = ((routes as any) as Routes).routes.find(({ regex }) =>
    regex.test(path)
  );

  const params = ((routes as any) as Routes).match(path).params;

  return currentRoute && { params, name: currentRoute.name };
};

export default getRouterName;
