import isValuesEmpty from '@anm/helpers/is/isValuesEmpty';

import { Request } from '../../types';

import {
  DeleteMemberParams,
  InvitationData,
  InviteHashParams,
  RoleUpdateParams,
  Team,
  TeamInvitation,
  TeamUpdateData
} from './types';

export * from './types';

export const getTeam = (request: Request) => () =>
  request<Team>({
    method: 'GET',
    url: 'team'
  });

export const uploadAvatar = (request: Request) => (file: File) => {
  const fd = new FormData();
  fd.append('file', file);

  return request({
    url: 'team/upload-avatar',
    data: fd,
    method: 'PUT'
  });
};
export const updateTeam = (request: Request) => async ({ avatar, ...data }: TeamUpdateData) => {
  avatar && (await uploadAvatar(request)(avatar));

  if (isValuesEmpty(data) && avatar) {
    return getTeam(request)();
  }

  if (isValuesEmpty(data)) {
    return Promise.resolve();
  }

  return request<Team>({
    data,
    method: 'PUT',
    url: 'team'
  });
};

export const deleteTeam = (request: Request) => () =>
  request({
    method: 'DELETE',
    url: 'team'
  });

export const inviteMember = (request: Request) => (data: InvitationData) =>
  request({
    data,
    method: 'POST',
    url: 'team/members/invite'
  });
export const acceptInvite = (request: Request) => ({ hash }: InviteHashParams) =>
  request({
    method: 'POST',
    url: `team/members/accept/${hash}`
  });

export const rejectInvite = (request: Request) => ({ hash }: InviteHashParams) =>
  request({
    method: 'POST',
    url: `team/members/reject/${hash}`
  });
export const getInvitationsList = (request: Request) => () =>
  request<TeamInvitation[]>({
    method: 'GET',
    url: 'team/members/invitations'
  });
export const deleteInvitation = (request: Request) => ({ hash }: InviteHashParams) =>
  request({ method: 'DELETE', url: `team/members/invitations/${hash}` });
export const getInvitation = (request: Request) => ({ hash }: InviteHashParams) =>
  request<TeamInvitation>({ method: 'GET', url: `team/members/invitations/${hash}` });

export const resendInvite = (request: Request) => ({ hash }: InviteHashParams) =>
  request({
    method: 'POST',
    url: `team/members/invitations/resend/${hash}`
  });
export const updateMemberRole = (request: Request) => ({ memberId, ...data }: RoleUpdateParams) =>
  request({
    data,
    method: 'PUT',
    url: `team/members/${memberId}/role`
  });
export const deleteMember = (request: Request) => ({ memberId }: DeleteMemberParams) =>
  request({
    method: 'DELETE',
    url: `team/members/${memberId}`
  });
