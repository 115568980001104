import { AnimatronProduct } from '@anm/api/modules/auth';

import vtConfig from '../config';

const { wave, studio } = vtConfig().urls;

const getWebsiteUrl = (productName: AnimatronProduct) =>
  productName === 'WAVE' ? wave : studio;

export default getWebsiteUrl;
