import { DeepPartial } from '../types/helpers';

import defaultConfig from './default';
import { Config, EnvType } from './types';
export * from './types';

const getConfig = (currentEnv: any): DeepPartial<Config> =>
  process.env.VT_ENV === 'prod' ? require('./prod').default : require(`./${currentEnv}`).default;

const config = (currentEnv: EnvType = (process.env.VT_ENV as EnvType) || 'dev') => {
  const envConfig = getConfig(currentEnv);
  const vtConfig: Config = require('lodash/merge')(defaultConfig, envConfig);

  return vtConfig;
};

export default config;
