import { Action } from 'redux';

import { deactivate, Notification } from '../../store/modules/notification';

export type UserNotificationsTypes =
  | typeof ADD_EMAIL
  | typeof SENT_CONFIRM_EMAIL
  | typeof EMAIL_CONFIRM_REQUEST
  | typeof RESEND_EMAIL_CONFIRM_REQUEST
  | typeof EMAIL_CONFIRM_SUCCESS
  | typeof EMAIL_CONFIRM_FAILURE
  | typeof CONFIRM_EMAIL_SUCCESS
  | typeof CHANGE_PASSWORD_SUCCESS
  | typeof APP_SUMO_SUCCESS_STACKED
  | typeof CONFIRM_APP_SUMO_EMAIL;

export const ADD_EMAIL = 'wave.video/notifications/user/ADD_EMAIL';
export const SENT_CONFIRM_EMAIL =
  'wave.video/notifications/user/SENT_CONFIRM_EMAIL';
export const CONFIRM_APP_SUMO_EMAIL =
  'wave.video/notifications/user/CONFIRM_APP_SUMO_EMAIL';
export const APP_SUMO_SUCCESS_STACKED =
  'wave.video/notifications/user/APP_SUMO_SUCCESS_STACKED';
export const EMAIL_CONFIRM_REQUEST =
  'wave.video/notifications/user/EMAIL_CONFIRM_REQUEST';
export const RESEND_EMAIL_CONFIRM_REQUEST =
  'wave.video/notifications/user/RESEND_EMAIL_CONFIRM_REQUEST';
export const EMAIL_CONFIRM_SUCCESS =
  'wave.video/notifications/user/EMAIL_CONFIRM_SUCCESS';
export const EMAIL_CONFIRM_FAILURE =
  'wave.video/notifications/user/EMAIL_CONFIRM_FAILURE';
export const CONFIRM_EMAIL_SUCCESS =
  'wave.video/notifications/user/CONFIRM_EMAIL_SUCCESS';
export const CHANGE_PASSWORD_SUCCESS =
  'wave.video/notifications/user/CHANGE_PASSWORD_SUCCESS';

const userNotifications = (
  type: UserNotificationsTypes,
  action: Action | Function = deactivate(),
  data?: any
): Notification => {
  switch (type) {
    case ADD_EMAIL:
      return {
        id: ADD_EMAIL,
        icon: 'images/icons/notification/confirm-email-success.svg',
        title: 'Please Add Your Email',
        description:
          'Add your email to help ensure you can log in, even if you forget your password.',
        button: {
          action,
          title: 'Add'
        }
      };

    case SENT_CONFIRM_EMAIL:
      return {
        id: SENT_CONFIRM_EMAIL,
        icon: 'images/icons/notification/confirm-email-success.svg',
        title: 'We have sent you a confirmation email.',
        description: `Please check your <b>${data.email}</b>`,
        button: { action, title: 'Ok' }
      };

    case CONFIRM_APP_SUMO_EMAIL:
      return {
        id: CONFIRM_APP_SUMO_EMAIL,
        icon: 'images/icons/notification/happy.svg',
        title: 'Thanks for activating your<br /> AppSumo lifetime plan!',
        description: `Please confirm <b>${data.email}</b> to help ensure you can log in to Wave.video, even if you forget your password.`,
        button: { action, title: 'Got it!' }
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        id: CHANGE_PASSWORD_SUCCESS,
        icon: 'images/icons/notification/happy.svg',
        title: 'Password was changed',
        description: 'You success change your password', // TODO change this message to more designed
        button: { action, title: 'Done' }
      };

    case APP_SUMO_SUCCESS_STACKED:
      return {
        id: APP_SUMO_SUCCESS_STACKED,
        icon: 'images/icons/notification/happy.svg',
        title: 'Thanks for activating your<br /> AppSumo lifetime plan!',
        description: '',
        button: { action, title: 'Got it!' }
      };
    case RESEND_EMAIL_CONFIRM_REQUEST:
      return {
        id: RESEND_EMAIL_CONFIRM_REQUEST,
        icon: 'images/icons/notification/confirm-email-success.svg',
        title: 'Please Confirm Your Email',
        description: `Confirm your <b>${data.email}</b> to help ensure you can log in, even if you forget your password.`,
        button: { action, title: 'Resend Confirmation Email' }
      };

    case CONFIRM_EMAIL_SUCCESS:
      return {
        id: CONFIRM_EMAIL_SUCCESS,
        icon: 'images/icons/notification/confirm-email-success.svg',
        title: 'Thanks for confirming your email!',
        description:
          'You can now make videos, restore your password, and get notifications from us. Happy creating!',
        button: {
          action,
          title: 'Ok'
        }
      };

    case EMAIL_CONFIRM_REQUEST:
      return {
        id: EMAIL_CONFIRM_REQUEST,
        icon: 'images/icons/notification/confirm-email-success.svg',
        title: 'Please Confirm Your Email',
        description: `We've sent you a link to <b>${data.email}</b> to confirm your email. This helps to ensure you can log in, even if you forget your password.`,
        button: {
          action,
          title: 'Ok'
        }
      };

    case EMAIL_CONFIRM_SUCCESS:
      return {
        id: EMAIL_CONFIRM_SUCCESS,
        icon: 'images/icons/notification/confirm-email-success.svg',
        title: 'We’ve Resent You the Confirmation Email',
        description: `Confirm <b>${data.email}</b> to help ensure you can log in, even if you forget your password.`
      };

    case EMAIL_CONFIRM_FAILURE:
      return {
        id: EMAIL_CONFIRM_FAILURE,
        icon: 'images/icons/notification/confirm-email-failure.svg',
        title: 'Cannot Send Confirmation Email',
        description:
          'There have been problems sending confirmation email, please contact us at <a href="mailto:feedback@wave.video">feedback@wave.video</a>'
      };
  }
};

export default userNotifications;
