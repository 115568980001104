import { call, put, select, take } from '@anm/helpers/saga/effects';
import { takeType } from '@anm/helpers/saga/typesafe-actions';

import { userActions, userSelectors } from '../user';

import { appMetaActions } from '.';

function* updateUserWorkspace() {
  const profile = yield* select(userSelectors.selectUserProfile);

  if (!profile?.teamId) {
    yield* put(appMetaActions.update({ workspace: 'user' }));
  }
}

function* watchUserChanged() {
  while (true) {
    yield* take([takeType(userActions.getUserAsync.success), takeType(userActions.updateAsync.success)]);
    yield* call(updateUserWorkspace);
  }
}

export default () => [call(watchUserChanged)];
