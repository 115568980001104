import { Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import { fetchEmbedStatsAsync } from '../actions';
import { UserActions } from '../types';

import { initialUserState } from '.';
import { decrementEmbedStats, incrementEmbedStats } from './../actions';

const embedStatsReducer: Reducer<typeof initialUserState, UserActions> = (
  state = initialUserState,
  action
) => {
  switch (action.type) {
    case getType(fetchEmbedStatsAsync.request): {
      return {
        ...state,
        embedStats: {
          isError: false,
          isPending: true
        }
      };
    }
    case getType(fetchEmbedStatsAsync.success): {
      return {
        ...state,
        embedStats: {
          data: action.payload,
          isError: false,
          isPending: false
        }
      };
    }
    case getType(fetchEmbedStatsAsync.failure): {
      const error = action.payload;

      return {
        ...state,
        embedStats: {
          error,
          isError: true,
          isPending: false
        }
      };
    }
    case getType(incrementEmbedStats): {
      const prevEmbeds = state.embedStats;
      const prevUsedCount = prevEmbeds?.data?.used || 0;

      return {
        ...state,
        embedStats: {
          ...prevEmbeds,
          data: {
            ...prevEmbeds.data!,
            used: prevUsedCount + 1
          }
        }
      };
    }
    case getType(decrementEmbedStats): {
      const prevEmbeds = state.embedStats;
      const prevUsedCount = prevEmbeds?.data?.used || 0;

      return {
        ...state,
        embedStats: {
          ...prevEmbeds,
          data: {
            ...prevEmbeds.data!,
            used: prevUsedCount - 1
          }
        }
      };
    }
    default:
      return state;
  }
};

export default embedStatsReducer;
