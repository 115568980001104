import {
  ANIMATRON_API_PROD,
  ANIMATRON_ASSETS_PROD,
  ANIMATRON_LIBRARY_PROD,
  ANIMATRON_PROD,
  WAVE_API_PROD,
  WAVE_EMBED_PROD,
  WAVE_PROD,
  WAVE_WATCH_PROD,
  WEBINAR_PROD
} from '@anm/constants/domains';
import {
  APPLE_API_KEY_PROD,
  APPLE_AUTH_URL_PROD,
  FACEBOOK_ANALYTIC_WAVE_PROD,
  FACEBOOK_API_KEY_PROD,
  FACEBOOK_AUTH_URL,
  FRONT_CHAT_STUDIO_PROD,
  FRONT_CHAT_WAVE_PROD,
  FULLSTORY_ANALYTIC_PROD,
  GOOGLE_ANALYTIC_WAVE_PROD,
  GOOGLE_API_KEY_PROD,
  GOOGLE_AUTH_URL,
  MIXPANEL_ANALYTIC_API_HOST,
  MIXPANEL_ANALYTIC_STUDIO_PROD,
  MIXPANEL_ANALYTIC_WAVE_PROD,
  SEMRUSH_APP_ID_PROD,
  SSL_PROD,
  STRIPE_KEY_PROD,
  TWITTER_AUTH_URL
} from '@anm/constants/services';
import {
  STREAMING_LIVE_API_URL_PROD,
  STREAMING_ROOM_API_URL_PROD,
  STREAMING_STUDIO_URL_PROD
} from '@anm/constants/streaming';
import getDomainNameFromUrl from '@anm/helpers/get/getDomainNameFromUrl';
import { DeepPartial } from 'helpers';

import { Config } from './types';

const configs: DeepPartial<Config> = {
  ssl: SSL_PROD,
  port: 3000,
  isProd: true,
  stripe: { key: STRIPE_KEY_PROD },
  urls: {
    api: ANIMATRON_API_PROD,
    apiWave: WAVE_API_PROD,
    cdn: `${ANIMATRON_ASSETS_PROD}video-templates/`,
    wave: WAVE_PROD,
    embed: WAVE_EMBED_PROD,
    studio: `${ANIMATRON_PROD}studio/`,
    editor: `${WAVE_PROD}editor`,
    webinarUrl: WEBINAR_PROD,
    studioEditor: 'https://editor.animatron.com/',
    calendarData: ANIMATRON_LIBRARY_PROD,
    videoLanding: WAVE_WATCH_PROD,
    animatronRoot: ANIMATRON_PROD,
    pricingDataUrl: `${ANIMATRON_ASSETS_PROD}pricing`,
    streamingStudio: STREAMING_STUDIO_URL_PROD,
    streamingRoomApi: STREAMING_ROOM_API_URL_PROD,
    streamingLiveApi: STREAMING_LIVE_API_URL_PROD,
    customDomainUrl: 'https://custom-domains-api.animatron.com'
  },
  support: {
    intercom: {
      enable: true,
      ids: {
        WAVE: FRONT_CHAT_WAVE_PROD,
        STUDIO: FRONT_CHAT_STUDIO_PROD
      }
    }
  },
  analytics: {
    google: {
      enable: true,
      ids: {
        WAVE: GOOGLE_ANALYTIC_WAVE_PROD,
        STUDIO: GOOGLE_ANALYTIC_WAVE_PROD
      },
      domains: {
        WAVE: getDomainNameFromUrl(ANIMATRON_PROD),
        STUDIO: getDomainNameFromUrl(WAVE_PROD)
      }
    },
    facebook: {
      enable: false,
      ids: {
        WAVE: FACEBOOK_ANALYTIC_WAVE_PROD,
        STUDIO: FACEBOOK_ANALYTIC_WAVE_PROD
      }
    },
    mixpanel: {
      enable: false,
      ids: {
        WAVE: MIXPANEL_ANALYTIC_WAVE_PROD,
        STUDIO: MIXPANEL_ANALYTIC_STUDIO_PROD
      },
      apiHosts: {
        WAVE: WAVE_API_PROD + MIXPANEL_ANALYTIC_API_HOST,
        STUDIO: ANIMATRON_API_PROD + MIXPANEL_ANALYTIC_API_HOST
      }
    },
    fullstory: {
      enable: false,
      ids: {
        WAVE: FULLSTORY_ANALYTIC_PROD,
        STUDIO: FULLSTORY_ANALYTIC_PROD
      }
    },
    hotjar: {
      enable: false
    }
  },
  langs: {
    languages: ['de', 'pt', 'br', 'ca', 'uk', 'es', 'id', 'it', 'fr', 'nl', 'pl', 'ru', 'ua', 'ja', 'zh']
  },
  socialNetworks: {
    apple: {
      apiKey: APPLE_API_KEY_PROD,
      authUrl: APPLE_AUTH_URL_PROD
    },
    google: {
      apiKey: GOOGLE_API_KEY_PROD,
      authUrl: GOOGLE_AUTH_URL
    },
    twitter: {
      authUrl: TWITTER_AUTH_URL
    },
    facebook: {
      apiKey: FACEBOOK_API_KEY_PROD,
      authUrl: FACEBOOK_AUTH_URL
    }
  },
  semrush: {
    appId: SEMRUSH_APP_ID_PROD
  }
};

export default configs;
