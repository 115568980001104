import { ApiError } from '@anm/api';
import {
  AudioDisputeParams,
  FetchStockParams,
  StockResponse
} from '@anm/api/modules/stocks';
import {
  createAction,
  createAsyncAction
} from '@anm/helpers/saga/typesafe-actions';

export const clearStocks = createAction('wave.video/stocks/CLEAR_STOCKS')();

export const fetchStocks = createAction('wave.video/stocks/FETCH_STOCKS')<
  FetchStockParams
>();

export const fetchStocksAsync = createAsyncAction(
  'wave.video/stocks/FETCH_STOCKS_REQUEST',
  'wave.video/stocks/FETCH_STOCKS_SUCCESS',
  'wave.video/stocks/FETCH_STOCKS_FAILURE'
)<FetchStockParams, StockResponse, ApiError>();

export const clearYoutubeChannelsState = createAction(
  'wave.video/stocks/CLEAR_CHECK_YOUTUBE_CHANNEL'
)();

export const checkYoutubeChannelIdAsync = createAsyncAction(
  'wave.video/stocks/CHECK_YOUTUBE_CHANNEL_ID_REQUEST',
  'wave.video/stocks/CHECK_YOUTUBE_CHANNEL_ID_SUCCESS',
  'wave.video/stocks/CHECK_YOUTUBE_CHANNEL_ID_FAILURE'
)<string, string, ApiError>();

export const clearSubmitYoutubeDisputeCopyrightClaimState = createAction(
  'wave.video/stocks/CLEAR_SUBMIT_YOUTUBE_COPYRIGHT_CLAIM'
)();

export const submitYoutubeDisputeCopyrightClaim = createAction(
  'wave.video/stocks/SUBMIT_YOUTUBE_COPYRIGHT_CLAIM'
)<AudioDisputeParams>();

export const submitYoutubeDisputeCopyrightClaimAsync = createAsyncAction(
  'wave.video/stocks/SUBMIT_YOUTUBE_COPYRIGHT_CLAIM_REQUEST',
  'wave.video/stocks/SUBMIT_YOUTUBE_COPYRIGHT_CLAIM_SUCCESS',
  'wave.video/stocks/SUBMIT_YOUTUBE_COPYRIGHT_CLAIM_FAILURE'
)<AudioDisputeParams, void, ApiError>();
