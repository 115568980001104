import { Request } from '@anm/api';
import {
  DEFAULT_MEDIA_KEY,
  LAST_USED_BRAND,
  LAST_USED_CAMERA_RESOLUTION,
  LAST_USED_STREAM_RESOLUTION,
  LIVE_STUDIO_SHOWN,
  MY_ROOM_ID,
  VIRTUAL_BACKGROUNDS_KEY,
  VIRTUAL_BG_GREEN_SCREEN
} from '@anm/constants/keys';
import { VideoResolution } from '@anm/data/media/videoConstrains';
import { BackgroundConfig } from 'types/virtualBackground';

import { StreamingResolution } from '../subscription';

export type VirtualBGPref = 'none' | 'auto' | 'green-screen';

export type UserPrefs = {
  [LAST_USED_BRAND]: string;
  [MY_ROOM_ID]: string;
  [LIVE_STUDIO_SHOWN]: boolean;
  [VIRTUAL_BG_GREEN_SCREEN]: VirtualBGPref;
  [DEFAULT_MEDIA_KEY]: boolean;
  [LAST_USED_STREAM_RESOLUTION]: StreamingResolution;
  [LAST_USED_CAMERA_RESOLUTION]: VideoResolution;
  [VIRTUAL_BACKGROUNDS_KEY]: BackgroundConfig | null;
};

export const getUserPrefs = (request: Request) => () =>
  request<UserPrefs>({
    url: 'userprefs',
    method: 'GET'
  });

export const updateUserPrefs = (request: Request) => (data: Partial<UserPrefs>) =>
  request<UserPrefs>({
    data,
    url: 'userprefs',
    method: 'POST'
  });

export const updatePrefferedBrand = (request: Request) => (brandId: number) =>
  request<UserPrefs>({
    url: 'userprefs',
    method: 'POST',
    data: {
      [LAST_USED_BRAND]: `${brandId}`
    }
  });
