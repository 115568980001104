import { Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import {
  checkYoutubeChannelIdAsync,
  clearYoutubeChannelsState
} from '../actions';
import { StockActions, YoutubeChannelState } from '../types';

export const initialYoutubeChannelsState: YoutubeChannelState = {
  error: null,
  isError: false,
  isPending: false
};

const stocksReducer: Reducer<YoutubeChannelState, StockActions> = (
  state = initialYoutubeChannelsState,
  action
) => {
  switch (action.type) {
    case getType(clearYoutubeChannelsState): {
      return initialYoutubeChannelsState;
    }
    case getType(checkYoutubeChannelIdAsync.request): {
      return {
        ...state,
        channelId: action.payload,
        error: null,
        isError: false,
        isPending: true
      };
    }
    case getType(checkYoutubeChannelIdAsync.success): {
      return {
        ...state,
        isPending: false
      };
    }
    case getType(checkYoutubeChannelIdAsync.failure): {
      const { payload: newError } = action;
      return {
        ...state,
        isError: true,
        error: newError,
        isPending: false
      };
    }
    default:
      return state;
  }
};

export default stocksReducer;
