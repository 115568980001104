import createApiInstance from '../helpers/createApiInstance';
import presetAxiosInstance from '../helpers/presetAxiosInstance';
import * as analytics from '../modules/analytics';
import * as auth from '../modules/auth';
import * as brands from '../modules/brands';
import * as domains from '../modules/customDomains';
import * as hosting from '../modules/hosting';
import * as pricing from '../modules/pricing';
import * as projects from '../modules/projects';
import * as stocks from '../modules/stocks';
import * as storyboard from '../modules/storyboard';
import * as subscription from '../modules/subscription';
import * as team from '../modules/team';
import templates from '../modules/templates';
import * as uploads from '../modules/uploads';
import * as user from '../modules/user';
import * as userprefs from '../modules/userprefs';
import * as video from '../modules/video';
import * as videoFolder from '../modules/videoFolder';
import * as waveEvents from '../modules/waveEvents';
import { ApiConfig } from '../types';

import liveStreamApi from './liveStreamApi';
import nodeStreamApi from './nodeStreamApi';
import stickersApi from './stickersApi';
import youtubeApi from './youtubeApi';

export * from '../types';

function genApi(apiConfig: ApiConfig) {
  const { bind, request, axiosInstance } = presetAxiosInstance(apiConfig);

  return {
    axiosInstance,
    auth: bind(auth),
    user: bind(user),
    team: bind(team),
    video: bind(video),
    stocks: bind(stocks),
    stream: { ...nodeStreamApi(), ...liveStreamApi() },
    brands: bind(brands),
    youtube: youtubeApi(),
    domains: bind(domains),
    pricing: bind(pricing),
    uploads: bind(uploads),
    hosting: bind(hosting),
    projects: bind(projects),
    userprefs: bind(userprefs),
    analytics: bind(analytics),
    storyboard: bind(storyboard),
    waveEvents: bind(waveEvents),
    templates: templates(request),
    stickersApi: stickersApi(),
    videoFolder: bind(videoFolder),
    subscription: bind(subscription)
  };
}

const mainApi = createApiInstance(genApi);

export default mainApi;
