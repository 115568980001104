import asyncEntityHandlers from '@anm/helpers/redux/asyncEntityHandlers';
import { PersistModule } from '@anm/helpers/redux/persistState';
import reduceFactory from '@anm/helpers/redux/reduceFactory';
import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import { AuthProviders } from '../../../config';

import { authAsync, authFinished, logoutAsync, sessionError, signupAnonAsync } from './actions';
import { AuthActions, AuthState } from './types';

const initialAuthState: AuthState = {
  anon: {
    data: null,
    isError: false,
    isPending: false
  },
  logout: {
    isError: false,
    isPending: false
  },
  token: null,
  isNew: false,
  method: null,
  isError: false,
  provider: null,
  isPending: false,
  isLoggedIn: false,
  sessionError: null
};

export const authPersistConfig: PersistModule = {
  path: 'auth',
  blackList: ['token', 'isPending', 'isError', 'error']
};

const authReducer: Reducer<AuthState, AuthActions> = (state = initialAuthState, action) => {
  switch (action.type) {
    case getType(logoutAsync.success): {
      return initialAuthState;
    }
    case getType(sessionError): {
      return {
        ...state,
        sessionError: action.payload
      };
    }
    case getType(authAsync.request): {
      const provider = ((action.payload && action.payload?.provider) || 'email') as AuthProviders;

      return {
        ...state,
        provider,
        isError: false,
        isPending: true
      };
    }
    case getType(authAsync.success): {
      const { isNew, encryptedToken } = action.payload;
      return {
        ...state,
        isNew,
        token: encryptedToken,
        method: isNew ? 'signup' : 'login',
        isPending: false
      };
    }
    case getType(signupAnonAsync.success): {
      const { encryptedToken } = action.payload;
      return {
        ...state,
        token: encryptedToken,
        anon: {
          ...state.anon,
          isPending: false
        }
      };
    }
    case getType(authFinished): {
      return {
        ...state,
        isLoggedIn: true
      };
    }
    case getType(authAsync.failure): {
      const { payload: newError } = action;
      return {
        ...state,
        isError: true,
        error: newError,
        isPending: false,
        isLoggedIn: false
      };
    }
    default:
      return reduceFactory(initialAuthState, {
        ...asyncEntityHandlers<AuthState>(signupAnonAsync, 'anon'),
        ...asyncEntityHandlers<AuthState>(logoutAsync, 'logout')
      })(state, action);
  }
};

export default authReducer;
