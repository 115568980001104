import { Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import {
  clearSubmitYoutubeDisputeCopyrightClaimState,
  submitYoutubeDisputeCopyrightClaimAsync
} from '../actions';
import { StockActions, YoutubeDisputeClaimState } from '../types';

export const initialYoutubeDisputeClaimState: YoutubeDisputeClaimState = {
  error: null,
  isError: false,
  isPending: false
};

const youtubeDisputeClaimReducer: Reducer<
  YoutubeDisputeClaimState,
  StockActions
> = (state = initialYoutubeDisputeClaimState, action) => {
  switch (action.type) {
    case getType(clearSubmitYoutubeDisputeCopyrightClaimState): {
      return initialYoutubeDisputeClaimState;
    }
    case getType(submitYoutubeDisputeCopyrightClaimAsync.request): {
      return {
        ...state,
        error: null,
        isError: false,
        isPending: true
      };
    }
    case getType(submitYoutubeDisputeCopyrightClaimAsync.success): {
      return {
        ...state,
        isPending: false
      };
    }
    case getType(submitYoutubeDisputeCopyrightClaimAsync.failure): {
      const { payload: newError } = action;
      return {
        ...state,
        isError: true,
        error: newError,
        isPending: false
      };
    }
    default:
      return state;
  }
};

export default youtubeDisputeClaimReducer;
