import { Request } from '../../types';

import {
  CalculateParams,
  CalculateResponseData,
  CardDetails,
  ChangeSubscriptionData,
  ChangeSubscriptionResponse,
  CouponData,
  SubscriptionDetails,
  UpdateCardData
} from './types';

export * from './types';

export const getSubscriptionList = (request: Request) => async () => {
  try {
    const list = await request<SubscriptionDetails[]>({
      url: 'subscription/list',
      method: 'GET',
      params: { product: 'WAVE' }
    });

    const teams = await request<SubscriptionDetails[]>({
      url: 'subscription/list-team',
      method: 'GET',
      params: { product: 'WAVE' }
    });

    return [...list, ...teams];
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSubscriptionCalculate = (request: Request) => (params: CalculateParams) =>
  request<CalculateResponseData>({
    params,
    url: 'subscription/calculate',
    method: 'GET'
  });

export const cancelSubscription = (request: Request) => () =>
  request<CalculateResponseData>({
    params: { product: 'WAVE' },
    url: 'subscription/cancel',
    method: 'DELETE'
  });

export const changeSubscription = (request: Request) => (data: ChangeSubscriptionData) =>
  request<ChangeSubscriptionResponse>({
    data,
    url: 'subscription/change',
    method: 'PUT'
  });

export const getSubscriptionHistory = (request: Request) => () =>
  request<CardDetails>({
    url: 'subscription/history',
    method: 'GET',
    params: { product: 'WAVE' }
  });

export const checkCoupon = (request: Request) => ({ code }: CouponData) =>
  request<string>({
    url: `/coupon/${code}`,
    method: 'GET'
  });

export const activateCoupon = (request: Request) => ({ code }: CouponData) =>
  request({
    url: `/coupon/${code}`,
    method: 'POST'
  });

export const updateCard = (request: Request) => (data: UpdateCardData) =>
  request({
    data,
    url: `subscription/card/update`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  });

export const upgradeAppSumo = (request: Request) => () =>
  request({
    url: `subscription/upgrade/appsumo`,
    method: 'POST'
  });

export const deleteCreditCard = (request: Request) => () =>
  request({
    url: 'subscription/card',
    method: 'DELETE'
  });
