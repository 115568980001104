import createApiInstance from '../helpers/createApiInstance';
import presetAxiosInstance from '../helpers/presetAxiosInstance';
import * as stickers from '../modules/giphyStickers';
import { ApiConfig } from '../types';
export * from '../types';

const genApi = (apiConfig: ApiConfig) => {
  const { bind } = presetAxiosInstance(apiConfig);
  return bind(stickers);
};

const stickersApi = createApiInstance(genApi);

export default stickersApi;
