import api from '@anm/api';
import { AnimatronProduct, AuthUserResponse } from '@anm/api/modules/auth';
import { ChangePasswordData, ConfirmEmailRequestData } from '@anm/api/modules/user';
import { isAnon } from '@anm/auth/helpers/authAnon';
import asyncEntity from '@anm/helpers/redux/asyncEntity';
import { all, call, fork, put, select, take } from '@anm/helpers/saga/effects';
import { takeType } from '@anm/helpers/saga/typesafe-actions';
import * as typeform from '@anm/helpers/typeform';
import compact from 'lodash/fp/compact';
import compose from 'lodash/fp/compose';
import head from 'lodash/fp/head';
import isEmpty from 'lodash/fp/isEmpty';
import { ActionWithPayload } from 'store';
import { User, UserPartial } from 'user';

import getWebsiteUrl from '../../../helpers/getWebsiteUrl';
import userNotifications, {
  ADD_EMAIL,
  CHANGE_PASSWORD_SUCCESS,
  CONFIRM_APP_SUMO_EMAIL,
  EMAIL_CONFIRM_FAILURE,
  EMAIL_CONFIRM_SUCCESS,
  RESEND_EMAIL_CONFIRM_REQUEST,
  SENT_CONFIRM_EMAIL
} from '../../../helpers/notifications/userNotifications';
import { CONFIRM_EMAIL_PARAM_NAME } from '../../../hooks/useEmailConfirmation';
import { ApplicationState } from '../../types';
import { appMetaSelectors } from '../appMeta';
import { authActions } from '../auth';
import * as notification from '../notification';
import { stockActions, stockSelectors } from '../stocks';
import { userActions, userSelectors } from '../user';

export function* getConfirmationEmailCallbackUrl() {
  const {
    appMeta: { product }
  }: ApplicationState = yield* select();

  return `${getWebsiteUrl(product)}videos?${CONFIRM_EMAIL_PARAM_NAME}=`;
}

const setUserAsync = asyncEntity(userActions.getUserAsync, (userProfile: User) => userProfile);
export const fetchUser = asyncEntity(userActions.getUserAsync, () => api().user.getUser());

export const confirmEmail = asyncEntity(userActions.confirmEmailAsync, (hash: string) => api().user.confirmEmail(hash));

const updateEmailAsync = asyncEntity(userActions.updateEmailAsync, (data: ConfirmEmailRequestData) =>
  api().user.updateEmail(data)
);

const resendConfirmEmailAsync = asyncEntity(userActions.resendConfirmEmailAsync, () => api().user.resendEmail());

const deleteAccount = asyncEntity(userActions.deleteAccountAsync, () => api().user.deleteAccount());

const fetchEmbedStats = asyncEntity(userActions.fetchEmbedStatsAsync, () => api().user.getUserEmbedStats());

const fetchStorageStats = asyncEntity(userActions.fetchStorageStatsAsync, () => api().user.getUserStorageStats());

const fetchHostingStats = asyncEntity(userActions.fetchHostingStatsAsync, () => api().user.getUserHostingStats());

const fetchTranscribeStats = asyncEntity(userActions.fetchTranscribeStatsAsync, () =>
  api().user.getUserTranscribeStats()
);

const fetchTextToSpeechStats = asyncEntity(userActions.fetchTextToSpeechStatsAsync, () =>
  api().user.getUserTextToSpeechStats()
);

const changePassword = asyncEntity(userActions.changePasswordAsync, (data: ChangePasswordData) =>
  api().user.changePassword(data)
);

function* updateUserAsync(userProfile: UserPartial) {
  if (isEmpty(userProfile)) return;

  yield asyncEntity(userActions.updateAsync, updateUser)(userProfile);
}

function* emailConfirmNotificationControl() {
  const userProfile: User = yield select(userSelectors.selectUserProfile);
  const waveSubscription: ReturnType<typeof userSelectors.selectWaveSubscription> = yield select(
    userSelectors.selectWaveSubscription
  );

  if (!userProfile || isAnon()) return;

  const {
    meta: { product },
    email,
    emailConfirmed
  } = userProfile;

  const addEmailAction = !email
    ? notification.add(userNotifications(ADD_EMAIL, userActions.openUpdateProfilePage(product)))
    : notification.remove([ADD_EMAIL]);
  yield put(addEmailAction);

  if (!email) return;

  const isUserAppSumo = waveSubscription?.name?.includes('AppSumo');
  const removeConfirmEmailAction = notification.remove([CONFIRM_APP_SUMO_EMAIL, RESEND_EMAIL_CONFIRM_REQUEST]);
  const confirmEmailAction = notification.add(
    userNotifications(
      isUserAppSumo ? CONFIRM_APP_SUMO_EMAIL : RESEND_EMAIL_CONFIRM_REQUEST,
      userActions.resendConfirmEmail(),
      { email }
    )
  );
  yield put(removeConfirmEmailAction);
  yield !emailConfirmed && put(confirmEmailAction);
}

function* showEmailConfirmSuccessNotification() {
  const user: User = yield select(userSelectors.selectUserProfile);

  if (!user) return;

  yield put(notification.activate(userNotifications(EMAIL_CONFIRM_SUCCESS, undefined, { email: user.email })));
}

function* showEmailConfirmFailureNotification() {
  yield put(notification.activate(userNotifications(EMAIL_CONFIRM_FAILURE)));
}

function* setUser(userProfile: User) {
  userProfile ? yield* call(setUserAsync, userProfile) : yield* call(fetchUser);
}

function* updateUser({ email, ...userProfile }: UserPartial) {
  const responses = yield* all([
    email && call(updateNotSameEmail, email),
    !isEmpty(userProfile) && call(api().user.updateUser, userProfile)
  ]);

  return compose(head, compact)(responses);
}

function* updateEmail(newEmail: string) {
  const product = yield* select(appMetaSelectors.selectAppMetaProduct);
  const callback = yield* call(getConfirmationEmailCallbackUrl);

  yield* call(updateEmailAsync, {
    product,
    callback,
    email: newEmail
  });

  return yield* call(api().user.getUser);
}

function* updateNotSameEmail(newEmail: string) {
  const currentEmail = yield* select(userSelectors.selectUserEmail);

  if (currentEmail === newEmail) {
    yield call(() => Promise.reject('newEmail and currentEmail are same'));
    return;
  }

  return yield* call(updateEmail, newEmail);
}

function* openDeleteFormAfterLogout() {
  const { payload: profile }: ActionWithPayload<string, User> = yield take(takeType(authActions.logoutAsync.success));
  typeform.openDeleteForm(profile.email);
}

function* watchUpdateUserSuccess() {
  while (true) {
    yield take(takeType(userActions.updateAsync.success));
    yield fork(emailConfirmNotificationControl);
  }
}

function* watchGetUser() {
  while (true) {
    yield* take(takeType(userActions.getUser));
    yield* fork(fetchUser);
  }
}

function* watchConfirmEmail() {
  while (true) {
    const { payload: hash } = yield* take(takeType(userActions.confirmEmail));

    yield* call(confirmEmail, hash);

    yield* put(userActions.getUser());
    yield* take(takeType(userActions.getUserAsync.success));
  }
}

function* watchUpdateUser() {
  while (true) {
    const { payload: userProfile }: ActionWithPayload<string, UserPartial> = yield take(
      takeType(userActions.updateUser)
    );

    yield fork(updateUserAsync, userProfile);
  }
}

function* updateUserAndShowNotification(user: UserPartial) {
  yield put(userActions.updateUser(user));
  if (!user.email) return;

  yield take(takeType(userActions.updateEmailAsync.success));
  yield put(notification.activate(userNotifications(SENT_CONFIRM_EMAIL, undefined, { email: user.email })));
}

function* watchChangePasswordSuccess() {
  while (true) {
    yield take(takeType(userActions.changePasswordAsync.success));

    yield put(notification.activate(userNotifications(CHANGE_PASSWORD_SUCCESS)));
  }
}

function* watchUpdateUserAndShowNotification() {
  while (true) {
    const { payload: userProfile }: ReturnType<typeof userActions.updateUserAndShowNotification> = yield* take(
      takeType(userActions.updateUserAndShowNotification)
    );

    yield* fork(updateUserAndShowNotification, userProfile);
  }
}

function* watchResendConfirmEmail() {
  while (true) {
    yield* take(takeType(userActions.resendConfirmEmail));
    yield* call(resendConfirmEmailAsync);
  }
}

function* watchDeleteAccount() {
  while (true) {
    yield take(takeType(userActions.deleteAccount));
    yield call(deleteAccount);
  }
}

function* watchDeleteAccountSuccess() {
  while (true) {
    yield take(takeType(userActions.deleteAccountAsync.success));
    yield put(authActions.logout());
    yield fork(openDeleteFormAfterLogout);
  }
}

function* watchChangePassword() {
  while (true) {
    const { payload }: ActionWithPayload<string, ChangePasswordData> = yield take(takeType(userActions.changePassword));

    yield call(changePassword, payload, payload);
  }
}

function* watchResendConfirmEmailSuccess() {
  while (true) {
    yield take(takeType(userActions.resendConfirmEmailAsync.success));
    yield fork(showEmailConfirmSuccessNotification);
  }
}

function* watchResendConfirmEmailFailure() {
  while (true) {
    yield take(takeType(userActions.updateEmailAsync.failure));
    yield fork(showEmailConfirmFailureNotification);
  }
}

function* watchAuthFinished() {
  while (true) {
    const {
      payload: { userProfile }
    }: ActionWithPayload<string, AuthUserResponse> = yield take(takeType(authActions.authFinished));

    yield call(setUser, userProfile);
  }
}

function* watchOpenUpdateProfilePage() {
  while (true) {
    const { payload: productName }: ActionWithPayload<string, AnimatronProduct> = yield take(
      takeType(userActions.openUpdateProfilePage)
    );
    window.open(`${getWebsiteUrl(productName)}account/profile`);
  }
}

function* watchGetEmbedsStats() {
  while (true) {
    yield take(takeType(userActions.fetchEmbedStats));

    yield fork(fetchEmbedStats);
  }
}

function* watchGetHostingStats() {
  while (true) {
    yield take(takeType(userActions.fetchHostingStats));

    yield fork(fetchHostingStats);
  }
}

function* watchGetStorageStats() {
  while (true) {
    yield take(takeType(userActions.fetchStorageStats));

    yield fork(fetchStorageStats);
  }
}

function* watchGetTranscribeStats() {
  while (true) {
    yield take(takeType(userActions.fetchTranscribeStats));

    yield fork(fetchTranscribeStats);
  }
}
function* watchGetTextToSpeechStats() {
  while (true) {
    yield take(takeType(userActions.fetchTextToSpeechStats));

    yield fork(fetchTextToSpeechStats);
  }
}

const updateYoutubeChannelsListRequest = asyncEntity(userActions.updateYoutubeChannelsListAsync, (list: string[]) =>
  api().user.updateYoutubeChannelsList(list)
);

function* updateYoutubeChannelsList(channel: string, isDeleting = false) {
  const oldChannelsList = yield* select(userSelectors.selectUserYoutubeChannels);
  const userProfile = yield* select(userSelectors.selectUserProfile);

  if (!userProfile) return;

  const { meta: userMeta, ...otherUserProperties } = userProfile;
  let newChanelList = [...oldChannelsList];

  if (isDeleting) {
    newChanelList = newChanelList.filter(c => c !== channel);
  } else {
    newChanelList.push(channel);
  }

  try {
    yield fork(updateYoutubeChannelsListRequest, newChanelList);

    const user = {
      ...otherUserProperties,
      meta: {
        ...userMeta,
        youtubeChannels: newChanelList
      }
    };

    yield put(userActions.updateAsync.success(user));
  } catch {
    yield put(
      userActions.updateAsync.failure({
        code: 420,
        message: 'Something went wrong!'
      })
    );
  }
}

function* watchCheckYoutubeChannelSuccess() {
  while (true) {
    yield take(takeType(stockActions.checkYoutubeChannelIdAsync.success));
    const id = yield* select(stockSelectors.selectYoutubeChannelId);

    yield id && call(updateYoutubeChannelsList, id);
  }
}

function* watchDeleteYoutubeChannel() {
  while (true) {
    const { payload } = yield take(takeType(userActions.deleteYoutubeChannel));

    yield call(updateYoutubeChannelsList, payload, true);
  }
}

const userSagaWatchers = () => [
  fork(watchGetUser),
  fork(watchUpdateUser),
  fork(watchConfirmEmail),
  fork(watchAuthFinished),
  fork(watchDeleteAccount),
  fork(watchChangePassword),
  fork(watchGetEmbedsStats),
  fork(watchGetHostingStats),
  fork(watchGetStorageStats),
  fork(watchGetTranscribeStats),
  fork(watchUpdateUserSuccess),
  fork(watchResendConfirmEmail),
  fork(watchGetTextToSpeechStats),
  fork(watchDeleteYoutubeChannel),
  fork(watchDeleteAccountSuccess),
  fork(watchChangePasswordSuccess),
  fork(watchOpenUpdateProfilePage),
  fork(watchResendConfirmEmailSuccess),
  fork(watchResendConfirmEmailFailure),
  fork(watchCheckYoutubeChannelSuccess),
  fork(watchUpdateUserAndShowNotification)
];

export default userSagaWatchers;
