import * as analytic from '@anm/analytic';
import api, { ApiError } from '@anm/api';
import isApiError from '@anm/api/helpers/isApiError';
import { LoginData, OAuthData, SignUpData } from '@anm/api/modules/auth';
import { isAnon, setAnon } from '@anm/auth/helpers/authAnon';
import hasAuthAccess from '@anm/auth/helpers/hasAuthAccess';
import signupAnon from '@anm/auth/helpers/signupAnon';
import updateAuthCookie from '@anm/auth/helpers/updateAuthCookie';
import { isSessionErrorCode } from '@anm/data/sessionErrorCodes';
import * as affiliate from '@anm/helpers/affiliate';
import asyncEntity from '@anm/helpers/redux/asyncEntity';
import { safeRun } from '@anm/helpers/safeOperation';
import { call, cancel, fork, put, select, take } from '@anm/helpers/saga/effects';
import { takeType } from '@anm/helpers/saga/typesafe-actions';
import * as semrush from '@anm/helpers/semrush';
import { flushAuthInfo } from '@anm/helpers/stateful/collectAuthInfo';

import getWebsiteUrl from '../../../../helpers/getWebsiteUrl';
import setupApi from '../../../../helpers/setupApi';
import routes from '../../../../routes';
import { appMetaSelectors } from '../../appMeta';
import { selectAppMetaProduct } from '../../appMeta/selectors';
import * as notification from '../../notification';
import { userActions, userSelectors } from '../../user';
import { getConfirmationEmailCallbackUrl } from '../../user/saga';
import { selectUserProfile } from '../../user/selectors';
import * as authActions from '../actions';
import { isNewUser, selectAuthToken } from '../selectors';

const { Router } = routes;

function* signUpRequestWithCallback(signUpData: SignUpData) {
  const callback = yield* call(getConfirmationEmailCallbackUrl);

  const res = yield* call(
    api().auth.signup,
    {
      callback,
      ...signUpData
    },
    safeRun(flushAuthInfo) || {}
  );

  return res;
}

export const signupAsync = asyncEntity(authActions.authAsync, signUpRequestWithCallback);
export const signupAnonAsync = asyncEntity(authActions.signupAnonAsync, signupAnon);

const loginRequest = (data: LoginData) => api().auth.login(data);
const login = asyncEntity(authActions.authAsync, loginRequest);

const loginOAuthRequest = (data: OAuthData) => api().auth.loginOAuth(data, flushAuthInfo());
const loginOAuth = asyncEntity(authActions.authAsync, loginOAuthRequest);

function* setAuthCookie() {
  const token = yield* select(selectAuthToken);

  if (token) {
    yield* call(updateAuthCookie, token);
  }
}

function* logout() {
  const profile = yield* select(userSelectors.selectUserProfile);
  const product = yield* select(appMetaSelectors.selectAppMetaProduct);

  yield* put(authActions.logoutAsync.request(profile!));
  try {
    yield* call(updateAuthCookie, null);
    yield* call(analytic.trackVisit, {
      product,
      userProfile: null
    });

    yield* put(userActions.cleanUser());
    yield* put(notification.removeAll());
    yield* put(authActions.logoutAsync.success(profile!));
  } catch (error) {
    yield* put(authActions.logoutAsync.failure(error));
  }
}

function* resetAuthState() {
  yield* put(userActions.cleanUser());
  yield* put(notification.removeAll());
}

function* redirectAfterLogout() {
  const product = yield* select(selectAppMetaProduct);
  const websiteUrl = getWebsiteUrl(product);
  location.href = websiteUrl;
}

function* logoutWithRedirect(props?: authActions.LogoutWithRedirectProps) {
  if (!hasAuthAccess()) return;

  yield* put(authActions.logout());
  yield* take(authActions.logoutAsync.success);

  if (props) {
    const { route, params } = props;
    Router.pushRoute(route, params);
  } else {
    yield* fork(redirectAfterLogout);
  }
}

function* loginWithToken(token: string) {
  const currentToken = yield* select(selectAuthToken);
  if (currentToken === token) return;

  setupApi({ token });

  yield* put(
    authActions.authAsync.success({
      isNew: false,
      encryptedToken: token
    })
  );
}

function* setApiToken() {
  const token = yield* select(selectAuthToken);

  setupApi({ token });
}

function* deleteAnonUser() {
  const user = yield* select(selectUserProfile);
  const isNew = yield* select(isNewUser);
  const isUserGuest = yield* select(userSelectors.selectIsUserGuest);

  (isNew || (user && !isUserGuest)) && setAnon(false);
}

function* removeAffiliateStore() {
  const isNew = yield* select(isNewUser);
  if (isNew) {
    affiliate.clear();
  }
}

function* watchAuthSuccess() {
  while (true) {
    const { payload } = yield* take(takeType(authActions.authAsync.success));

    yield* fork(setApiToken);
    yield* fork(deleteAnonUser);
    yield* fork(setAuthCookie);
    yield* fork(removeAffiliateStore);

    yield put(authActions.authFinished(payload));
  }
}

function* watchAnonSignUpSuccess() {
  while (true) {
    yield* take(takeType(authActions.signupAnonAsync.success));

    yield* fork(setApiToken);
    yield* fork(setAuthCookie);
  }
}

function* watchSignUp() {
  while (true) {
    const { payload } = yield* take(takeType(authActions.signup));
    yield* call(signupAsync, payload);
  }
}
function* watchAnonSignUp() {
  while (true) {
    yield* take(takeType(authActions.signupAnon));
    yield* call(signupAnonAsync);
  }
}

function* doIfNotLogin(fn: any, ...arg: any[]) {
  if (!hasAuthAccess() || isAnon()) {
    yield* call(fn, ...arg);
  }
}

function* watchLoginWithToken() {
  while (true) {
    const { payload: token } = yield* take(takeType(authActions.loginWithToken));
    yield* call(loginWithToken, token);
  }
}

function* watchLogin() {
  while (true) {
    const { payload } = yield* take(takeType(authActions.login));
    const { product } = yield* select(appMetaSelectors.selectAppMeta);

    yield* call(doIfNotLogin, login, { product, ...payload });
  }
}

function* watchLoginOAuth() {
  while (true) {
    const { payload } = yield* take(takeType(authActions.loginOAuth));
    const { oauthService: provider } = payload;

    yield* call(doIfNotLogin, loginOAuth, payload, { provider });
  }
}

function* watchPostOAuthToken() {
  while (true) {
    const {
      payload: { token, provider }
    } = yield* take(takeType(authActions.postOAuthToken));
    const { entry, product } = yield* select(appMetaSelectors.selectAppMeta);

    yield* fork(loginOAuth, {
      entry,
      product,
      authToken: token,
      oauthService: provider
    } as OAuthData);
  }
}
function* watchLogout() {
  while (true) {
    yield* take(takeType(authActions.logout));
    yield* call(logout);
  }
}
function* watchResetAuthState() {
  while (true) {
    yield* take(takeType(authActions.resetAuthState));
    yield* call(resetAuthState);
  }
}

function* watchLogoutWithRedirect() {
  while (true) {
    const { payload } = yield* take(takeType(authActions.logoutWithRedirect));
    yield* fork(logoutWithRedirect, payload);
  }
}

function* handleUserSessionFailure(error: ApiError) {
  if (isApiError(error) && isSessionErrorCode(error.code) && !semrush.isSemrushIframe()) {
    yield* put(authActions.sessionError(error));
    Router.pushRoute('user-error');
  }
}

function* redirectToLogin() {
  Router.pushRoute('login', { redirectUrl: Router.router?.asPath || '' });
}

function* watchRequestsFailure() {
  while (true) {
    const { payload: error }: ReturnType<typeof userActions.updateAsync.failure> = yield take('*');

    yield* call(handleUserSessionFailure, error);
  }
}

function* watchLogoutOnSecurePage() {
  while (true) {
    yield* take(authActions.logoutAsync.success);

    yield* call(redirectToLogin);
  }
}

function* watchStartSessionWatcher() {
  while (true) {
    yield* take(takeType(authActions.startSessionWatcher));
    const requestsFailureTask = yield* fork(watchRequestsFailure);
    const logoutOnSecurePageTask = yield* fork(watchLogoutOnSecurePage);

    yield* take(takeType(authActions.stopSessionWatcher));
    yield* cancel(requestsFailureTask);
    yield* cancel(logoutOnSecurePageTask);
  }
}

const authSagaWatchers = () => [
  call(watchLogout),
  call(watchResetAuthState),
  call(watchLogoutWithRedirect),
  call(watchSignUp),
  call(watchAnonSignUp),
  call(watchLogin),
  call(watchLoginOAuth),
  call(watchLoginWithToken),
  call(watchPostOAuthToken),
  call(watchAuthSuccess),
  call(watchAnonSignUpSuccess),
  call(watchStartSessionWatcher)
];

export default authSagaWatchers;
