import { Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import { resendConfirmEmailAsync } from '../actions';
import { UserActions } from '../types';

import { initialUserState } from '.';

const resendConfirmEmailReducer: Reducer<
  typeof initialUserState,
  UserActions
> = (state = initialUserState, action) => {
  switch (action.type) {
    case getType(resendConfirmEmailAsync.request): {
      return {
        ...state,
        isResendEmailPending: true
      };
    }
    case getType(resendConfirmEmailAsync.success):
    case getType(resendConfirmEmailAsync.failure): {
      return {
        ...state,
        isResendEmailPending: false
      };
    }

    default:
      return state;
  }
};

export default resendConfirmEmailReducer;
