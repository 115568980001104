import { Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import { fetchTextToSpeechStatsAsync } from '../actions';
import { UserActions } from '../types';

import { initialUserState } from '.';

const text2SpeechStatsReducer: Reducer<typeof initialUserState, UserActions> = (
  state = initialUserState,
  action
) => {
  switch (action.type) {
    case getType(fetchTextToSpeechStatsAsync.request): {
      return {
        ...state,
        textToSpeechStats: {
          isError: false,
          isPending: true
        }
      };
    }
    case getType(fetchTextToSpeechStatsAsync.success): {
      return {
        ...state,
        textToSpeechStats: {
          data: action.payload,
          isError: false,
          isPending: false
        }
      };
    }
    case getType(fetchTextToSpeechStatsAsync.failure): {
      const error = action.payload;

      return {
        ...state,
        textToSpeechStats: {
          error,
          isError: true,
          isPending: false
        }
      };
    }
    default:
      return state;
  }
};

export default text2SpeechStatsReducer;
