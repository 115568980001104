import { PersistModule } from '@anm/helpers/redux/persistState';
import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import { activate, add, deactivate, remove, removeAll } from './actions';
import { NotificationActions, NotificationState } from './types';

const initialNotificationState: NotificationState = {
  active: null,
  notifications: []
};

export const notificationPersistConfig: PersistModule = {
  path: 'notification',
  blackList: ['active', 'appNotifications']
};
const notificationReducer: Reducer<NotificationState, NotificationActions> = (
  state = initialNotificationState,
  action
) => {
  switch (action.type) {
    case getType(add): {
      const notification = action.payload;
      const isAlreadyExist = state.notifications.find(({ id }) => notification.id === id);

      return isAlreadyExist
        ? state
        : {
            ...state,
            notifications: [...state.notifications, notification]
          };
    }
    case getType(remove): {
      const removeIds = action.payload;
      return {
        ...state,
        notifications: state.notifications.filter(({ id }) => !removeIds.includes(id))
      };
    }
    case getType(removeAll): {
      return {
        ...initialNotificationState
      };
    }
    case getType(activate): {
      const notification = action.payload;
      return {
        ...state,
        active: notification
      };
    }
    case getType(deactivate): {
      return {
        ...state,
        active: null
      };
    }

    default:
      return state;
  }
};

export default notificationReducer;
