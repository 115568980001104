import { createAction } from '@anm/helpers/saga/typesafe-actions';

import { Notification } from './types';

export const add = createAction('wave.video/notification/ADD_NOTIFICATION')<Notification>();

export const remove = createAction('wave.video/notification/REMOVE_NOTIFICATION')<string[]>();

export const removeAll = createAction('wave.video/notification/REMOVE_ALL_NOTIFICATION')();

export const activate = createAction('wave.video/notification/ACTIVATE_NOTIFICATION')<Notification>();

export const deactivate = createAction('wave.video/notification/DEACTIVATE_NOTIFICATION')();
