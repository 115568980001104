import api from '@anm/api';
import { AudioDisputeParams, FetchStockParams } from '@anm/api/modules/stocks';
import { call, fork, put, select, take } from '@anm/helpers/saga/effects';
import { takeType } from '@anm/helpers/saga/typesafe-actions';
import isEqual from 'lodash/fp/isEqual';

import asyncEntity from '@anm/helpers/redux/asyncEntity';
import { userActions } from '../user';

import { stockActions, stockSelectors } from '.';

const fetchVideoStocksAsync = asyncEntity(stockActions.fetchStocksAsync, (props: FetchStockParams) =>
  api().stocks.fetchVideoStocks(props)
);

const fetchImageStocksAsync = asyncEntity(stockActions.fetchStocksAsync, (props: FetchStockParams) =>
  api().stocks.fetchImageStocks(props)
);

function* watchFetchStocks() {
  while (true) {
    const { payload }: ReturnType<typeof stockActions.fetchStocks> = yield take(takeType(stockActions.fetchStocks));

    const prevParams: ReturnType<typeof stockSelectors.selectStocksParams> = yield select(
      stockSelectors.selectStocksParams
    );
    const canFetchImages = payload.isImages;
    const params = { ...payload };
    const isEqualParams = isEqual(prevParams, params);

    if (isEqualParams) return;

    yield put(stockActions.clearStocks());

    yield* fork(fetchVideoStocksAsync, params);
    if (canFetchImages) {
      yield* fork(fetchImageStocksAsync, params);
    }
  }
}

const checkYoutubeChannelIdAsync = asyncEntity(stockActions.checkYoutubeChannelIdAsync, (channel: string) =>
  api().stocks.checkYoutubeChannelId(channel)
);

function* watchCheckYoutubeChannelId() {
  while (true) {
    const { payload }: ReturnType<typeof userActions.addYoutubeChannel> = yield take(
      takeType(userActions.addYoutubeChannel)
    );

    yield fork(checkYoutubeChannelIdAsync, payload);
  }
}

function* watchCheckYoutubeChannelIdSuccess() {
  while (true) {
    yield take(takeType(stockActions.checkYoutubeChannelIdAsync.success));

    yield take(takeType(userActions.addYoutubeChannel));
  }
}

const submitYoutubeDisputeCopyrightClaimAsync = asyncEntity(
  stockActions.submitYoutubeDisputeCopyrightClaimAsync,
  (params: AudioDisputeParams) => api().stocks.submitBlocksAudioDispute(params)
);

function* watchSubmitYoutubeDisputeCopyrightClaim() {
  while (true) {
    const { payload }: ReturnType<typeof stockActions.submitYoutubeDisputeCopyrightClaim> = yield take(
      takeType(stockActions.submitYoutubeDisputeCopyrightClaim)
    );

    yield fork(submitYoutubeDisputeCopyrightClaimAsync, payload);
  }
}

const stocksWatchers = () => [
  call(watchFetchStocks),
  call(watchCheckYoutubeChannelId),
  call(watchCheckYoutubeChannelIdSuccess),
  call(watchSubmitYoutubeDisputeCopyrightClaim)
];

export default stocksWatchers;
