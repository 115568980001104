import { AUTH_COOKIE } from '@anm/constants/auth';
import {
  ANIMATRON_API_TEST,
  ANIMATRON_ASSETS_TEST,
  ANIMATRON_LIBRARY_TEST,
  ANIMATRON_TEST,
  WAVE_API_TEST,
  WAVE_EMBED_TEST,
  WAVE_TEST,
  WAVE_WATCH_TEST,
  WEBINAR_TEST
} from '@anm/constants/domains';
import { blackFridaySale } from '@anm/constants/sale';
import {
  APPLE_API_KEY_TEST,
  APPLE_AUTH_URL_TEST,
  FACEBOOK_ANALYTIC_WAVE_TEST,
  FACEBOOK_API_KEY_TEST,
  FACEBOOK_AUTH_URL,
  FRONT_CHAT_STUDIO_TEST,
  FRONT_CHAT_WAVE_TEST,
  FULLSTORY_ANALYTIC_TEST,
  GOOGLE_ANALYTIC_WAVE_TEST,
  GOOGLE_API_KEY_TEST,
  GOOGLE_AUTH_URL,
  GOOGLE_CAPTCHA_KEY,
  HOTJAR_ANALYTIC,
  MIXPANEL_ANALYTIC_API_HOST,
  MIXPANEL_ANALYTIC_WAVE_TEST,
  MIXPANEL_CUSTOM_LIB_URL,
  SEMRUSH_APP_ID_TEST,
  SSL_TEST,
  STRIPE_KEY_TEST,
  TWITTER_AUTH_URL
} from '@anm/constants/services';
import {
  STREAMING_LIVE_API_URL_TEST,
  STREAMING_ROOM_API_URL_TEST,
  STREAMING_STUDIO_URL_TEST
} from '@anm/constants/streaming';
import { COMMON_AUDIO_EXTENSIONS } from '@anm/constants/supportedAudioExtensions';
import { COMMON_IMAGE_EXTENSIONS } from '@anm/constants/supportedImageExtensions';
import { COMMON_VIDEO_EXTENSIONS } from '@anm/constants/supportedVideoExtensions';
import getDomainNameFromUrl from '@anm/helpers/get/getDomainNameFromUrl';

import { Config, SupportedAudioExtensions, SupportedImageExtensions, SupportedVideoExtensions } from './types';

const defaultEnv: Config = {
  ssl: SSL_TEST,
  auth: {
    cookieName: AUTH_COOKIE,
    userLocalStorage: 'user'
  },
  urls: {
    api: ANIMATRON_API_TEST,
    apiWave: WAVE_API_TEST,
    cdn: '/',
    wave: WAVE_TEST,
    embed: WAVE_EMBED_TEST,
    studio: `${ANIMATRON_TEST}studio/`,
    editor: `${WAVE_TEST}editor`,
    webinarUrl: WEBINAR_TEST,
    studioEditor: 'https://editor.animatron-test.com/',
    animatronRoot: ANIMATRON_TEST,
    calendarData: ANIMATRON_LIBRARY_TEST,
    videoLanding: WAVE_WATCH_TEST,
    pricingDataUrl: `${ANIMATRON_ASSETS_TEST}pricing`,
    streamingStudio: STREAMING_STUDIO_URL_TEST,
    streamingRoomApi: STREAMING_ROOM_API_URL_TEST,
    streamingLiveApi: STREAMING_LIVE_API_URL_TEST,
    customDomainUrl: 'https://custom-domains-api.test.stacks.animatron-test.com'
  },
  sale: blackFridaySale,
  port: 3005,
  isProd: false,
  sentry: {
    dsn: 'https://a9d9072bc92a4d1f8cf898942ce8dfd6@o1082595.ingest.sentry.io/4503893088206848',
    release: 'video-templates'
  },
  stripe: { key: STRIPE_KEY_TEST },
  support: {
    intercom: {
      ids: {
        WAVE: FRONT_CHAT_WAVE_TEST,
        STUDIO: FRONT_CHAT_STUDIO_TEST
      },
      enable: false
    }
  },
  analytics: {
    google: {
      enable: true,
      ids: {
        WAVE: GOOGLE_ANALYTIC_WAVE_TEST,
        STUDIO: GOOGLE_ANALYTIC_WAVE_TEST
      },
      domains: {
        WAVE: getDomainNameFromUrl(ANIMATRON_TEST),
        STUDIO: getDomainNameFromUrl(WAVE_TEST)
      }
    },
    facebook: {
      enable: false,
      ids: {
        WAVE: FACEBOOK_ANALYTIC_WAVE_TEST,
        STUDIO: FACEBOOK_ANALYTIC_WAVE_TEST
      }
    },
    mixpanel: {
      enable: false,
      libUrl: MIXPANEL_CUSTOM_LIB_URL,
      ids: {
        WAVE: MIXPANEL_ANALYTIC_WAVE_TEST,
        STUDIO: MIXPANEL_ANALYTIC_WAVE_TEST
      },
      apiHosts: {
        WAVE: WAVE_API_TEST + MIXPANEL_ANALYTIC_API_HOST,
        STUDIO: ANIMATRON_API_TEST + MIXPANEL_ANALYTIC_API_HOST
      }
    },
    fullstory: {
      enable: false,
      ids: {
        WAVE: FULLSTORY_ANALYTIC_TEST,
        STUDIO: FULLSTORY_ANALYTIC_TEST
      }
    },
    hotjar: {
      enable: false,
      ids: {
        WAVE: HOTJAR_ANALYTIC,
        STUDIO: HOTJAR_ANALYTIC
      }
    }
  },
  langs: {
    languages: ['de', 'pt', 'br', 'ca', 'uk']
  },
  nextDevMode: false,
  socialNetworks: {
    apple: {
      apiKey: APPLE_API_KEY_TEST,
      authUrl: APPLE_AUTH_URL_TEST
    },
    google: {
      apiKey: GOOGLE_API_KEY_TEST,
      authUrl: GOOGLE_AUTH_URL
    },
    twitter: {
      authUrl: TWITTER_AUTH_URL
    },
    facebook: {
      apiKey: FACEBOOK_API_KEY_TEST,
      authUrl: FACEBOOK_AUTH_URL
    }
  },
  captcha: {
    apiKey: GOOGLE_CAPTCHA_KEY
  },
  semrush: {
    appId: SEMRUSH_APP_ID_TEST
  },
  supportedVideoExtensions: COMMON_VIDEO_EXTENSIONS as SupportedVideoExtensions[],
  supportedImageExtensions: COMMON_IMAGE_EXTENSIONS as SupportedImageExtensions[],
  supportedAudioExtensions: COMMON_AUDIO_EXTENSIONS as SupportedAudioExtensions[],
  supportedBrandingImageExtensions: ['jpg', 'jpeg', 'png', 'gif', 'svg'],
  uploadVideoDefaults: {
    metaInfo: {
      name: 'Untitled video',
      description: 'hosted by Wave.video'
    }
  },
  paidVideoLandingsThemes: ['deep', 'lily', 'mist', 'sand', 'dew', 'peony', 'night', 'daisy'],
  paidVideoLandingsLayouts: ['t3', 't4', 't5'],
  showBetaLabels: true
};

export default defaultEnv;
