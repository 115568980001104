import { Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import { fetchTranscribeStatsAsync } from '../actions';
import { UserActions } from '../types';

import { initialUserState } from '.';

const transcribeStatsReducer: Reducer<typeof initialUserState, UserActions> = (
  state = initialUserState,
  action
) => {
  switch (action.type) {
    case getType(fetchTranscribeStatsAsync.request): {
      return {
        ...state,
        transcribeStats: {
          isError: false,
          isPending: true
        }
      };
    }
    case getType(fetchTranscribeStatsAsync.success): {
      return {
        ...state,
        transcribeStats: {
          data: action.payload,
          isError: false,
          isPending: false
        }
      };
    }
    case getType(fetchTranscribeStatsAsync.failure): {
      const error = action.payload;

      return {
        ...state,
        transcribeStats: {
          error,
          isError: true,
          isPending: false
        }
      };
    }
    default:
      return state;
  }
};

export default transcribeStatsReducer;
