import { PersistModule } from '@anm/helpers/redux/persistState';
import { getType, ActionType } from '@anm/helpers/saga/typesafe-actions';
import isNil from 'lodash/fp/isNil';
import omitBy from 'lodash/fp/omitBy';
import { Reducer } from 'redux';

import * as appMetaActions from './actions';
import { AppMetaState } from './types';

type AppMetaActions = ActionType<typeof appMetaActions>;

const initialAppMetaState: AppMetaState = {
  path: '',
  entry: 'LANDING',
  product: 'WAVE',
  workspace: 'user',
  routerParams: {}
};

export const appMetaPersistConfig: PersistModule = {
  path: 'appMeta',
  blackList: ['path', 'routerParams']
};

const appMetaReducer: Reducer<AppMetaState, AppMetaActions> = (state = initialAppMetaState, action) => {
  switch (action.type) {
    case getType(appMetaActions.update): {
      return {
        ...state,
        ...omitBy(isNil)(action.payload)
      };
    }
    default:
      return state;
  }
};

export default appMetaReducer;
