import { ANIMATRON_ASSETS_PROD, ANIMATRON_ASSETS_TEST } from '@anm/constants/domains';
import { DeepPartial } from 'helpers';

import { Config } from './types';

const configs: DeepPartial<Config> = {
  port: 3000,
  isProd: false,
  urls: {
    cdn: `${ANIMATRON_ASSETS_TEST}video-templates/`,
    pricingDataUrl: `${ANIMATRON_ASSETS_PROD}pricing`
  },
  support: {
    intercom: {
      enable: true
    }
  }
};

export default configs;
