import { Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import { cleanStorageStats, fetchStorageStatsAsync } from '../actions';
import { UserActions } from '../types';

import { initialUserState } from '.';

const storageStatsReducer: Reducer<typeof initialUserState, UserActions> = (state = initialUserState, action) => {
  switch (action.type) {
    case getType(fetchStorageStatsAsync.request): {
      return {
        ...state,
        storageStats: {
          isError: false,
          isPending: true
        }
      };
    }
    case getType(fetchStorageStatsAsync.success): {
      return {
        ...state,
        storageStats: {
          data: action.payload,
          isError: false,
          isPending: false
        }
      };
    }
    case getType(fetchStorageStatsAsync.failure): {
      const error = action.payload;

      return {
        ...state,
        storageStats: {
          error,
          isError: true,
          isPending: false
        }
      };
    }

    case getType(cleanStorageStats): {
      return {
        ...state,
        storageStats: {
          isError: false,
          isPending: false
        }
      };
    }

    default:
      return state;
  }
};

export default storageStatsReducer;
