import { ApiError } from '@anm/api';
import { AnimatronProduct } from '@anm/api/modules/auth';
import { ChangePasswordData } from '@anm/api/modules/user';
import { createAction, createAsyncAction } from '@anm/helpers/saga/typesafe-actions';
import { CommonStats, HostingStats, User, UserPartial } from 'user';

export const openUpdateProfilePage = createAction('wave.video/auth/OPEN_UPDATE_PROFILE_PAGE')<AnimatronProduct>();

export const confirmEmail = createAction('wave.video/user/CONFIRM_EMAIL')<string>();
export const confirmEmailAsync = createAsyncAction(
  'wave.video/user/CONFIRM_EMAIL_REQUEST',
  'wave.video/user/CONFIRM_EMAIL_SUCCESS',
  'wave.video/user/CONFIRM_EMAIL_FAILURE'
)<string, string, ApiError>();

export const getUser = createAction('wave.video/user/GET_USER')();
export const getUserAsync = createAsyncAction(
  'wave.video/user/GET_USER_REQUEST',
  'wave.video/user/GET_USER_SUCCESS',
  'wave.video/user/GET_USER_FAILURE'
)<User, User, ApiError>();

export const cleanUser = createAction('wave.video/user/CLEAN_USER')<void>();

export const updateUserAndShowNotification = createAction('wave.video/user/UPDATE_USER_AND_SHOW_NOTIFICATION')<
  UserPartial
>();
export const updateUser = createAction('wave.video/user/UPDATE_USER')<UserPartial>();
export const updateAsync = createAsyncAction(
  'wave.video/user/UPDATE_USER_REQUEST',
  'wave.video/user/UPDATE_USER_SUCCESS',
  'wave.video/user/UPDATE_USER_FAILURE'
)<Partial<User>, User, ApiError>();

export const resendConfirmEmail = createAction('wave.video/user/RESEND_CONFIRM_EMAIL')();
export const resendConfirmEmailAsync = createAsyncAction(
  'wave.video/user/RESEND_CONFIRM_EMAIL_REQUEST',
  'wave.video/user/RESEND_CONFIRM_EMAIL_SUCCESS',
  'wave.video/user/RESEND_CONFIRM_EMAIL_FAILURE'
)<void, void, ApiError>();

export const deleteAccount = createAction('wave.video/user/DELETE_ACCOUNT')();
export const deleteAccountAsync = createAsyncAction(
  'wave.video/user/DELETE_ACCOUNT_REQUEST',
  'wave.video/user/DELETE_ACCOUNT_SUCCESS',
  'wave.video/user/DELETE_ACCOUNT_FAILURE'
)<void, void, ApiError>();

export const updateEmailAsync = createAsyncAction(
  'wave.video/user/UPDATE_EMAIL_REQUEST',
  'wave.video/user/UPDATE_EMAIL_SUCCESS',
  'wave.video/user/UPDATE_EMAIL_FAILURE'
)<void, void, ApiError>();

export const changePassword = createAction('wave.video/user/CHANGE_PASSWORD')<ChangePasswordData>();
export const changePasswordAsync = createAsyncAction(
  'wave.video/user/CHANGE_PASSWORD_REQUEST',
  'wave.video/user/CHANGE_PASSWORD_SUCCESS',
  'wave.video/user/CHANGE_PASSWORD_FAILURE'
)<ChangePasswordData, void, ApiError>();

export const fetchEmbedStats = createAction('wave.video/user/FETCH_USER_EMBED_STATS')();

export const fetchEmbedStatsAsync = createAsyncAction(
  'wave.video/user/FETCH_EMBED_STATS_REQUEST',
  'wave.video/user/FETCH_EMBED_STATS_SUCCESS',
  'wave.video/user/FETCH_EMBED_STATS_FAILURE'
)<void, CommonStats, ApiError>();

export const incrementEmbedStats = createAction('wave.video/user/INCREMENT_EMBED_STATS')();

export const decrementEmbedStats = createAction('wave.video/user/DECREMENT_EMBED_STATS')();

export const fetchHostingStats = createAction('wave.video/user/FETCH_USER_HOSTING_STATS')();

export const fetchHostingStatsAsync = createAsyncAction(
  'wave.video/user/FETCH_HOSTING_STATS_REQUEST',
  'wave.video/user/FETCH_HOSTING_STATS_SUCCESS',
  'wave.video/user/FETCH_HOSTING_STATS_FAILURE'
)<void, HostingStats, ApiError>();

export const fetchStorageStats = createAction('wave.video/user/FETCH_USER_STORAGE_STATS')();

export const cleanStorageStats = createAction('wave.video/user/CLEAN_USER_STORAGE_STATS')();

export const fetchStorageStatsAsync = createAsyncAction(
  'wave.video/user/FETCH_STORAGE_STATS_REQUEST',
  'wave.video/user/FETCH_STORAGE_STATS_SUCCESS',
  'wave.video/user/FETCH_STORAGE_STATS_FAILURE'
)<void, CommonStats, ApiError>();

export const fetchTranscribeStats = createAction('wave.video/user/FETCH_USER_TRANSCRIBE_STATS')();

export const fetchTranscribeStatsAsync = createAsyncAction(
  'wave.video/user/FETCH_TRANSCRIBE_STATS_REQUEST',
  'wave.video/user/FETCH_TRANSCRIBE_STATS_SUCCESS',
  'wave.video/user/FETCH_TRANSCRIBE_STATS_FAILURE'
)<void, CommonStats, ApiError>();

export const fetchTextToSpeechStats = createAction('wave.video/user/FETCH_USER_TEXT_TO_SPEECH_STATS')();

export const fetchTextToSpeechStatsAsync = createAsyncAction(
  'wave.video/user/FETCH_TEXT_TO_SPEECH_STATS_SUCCESS',
  'wave.video/user/FETCH_TEXT_TO_SPEECH_STATS_REQUEST',
  'wave.video/user/FETCH_TEXT_TO_SPEECH_STATS_FAILURE'
)<void, CommonStats, ApiError>();

export const addYoutubeChannel = createAction('wave.video/user/ADD_YOUTUBE_CHANNEL')<string>();

export const deleteYoutubeChannel = createAction('wave.video/user/DELETE_YOUTUBE_CHANNEL')<string>();

export const updateYoutubeChannelsListAsync = createAsyncAction(
  'wave.video/user/UPDATE_YOUTUBE_CHANNELS_LIST_REQUEST',
  'wave.video/user/UPDATE_YOUTUBE_CHANNELS_LIST_SUCCESS',
  'wave.video/user/UPDATE_YOUTUBE_CHANNELS_LIST_FAILURE'
)<string[], string, ApiError>();

export const setIsUserBlockedOnHosting = createAction('wave.video/user/SET_IS_USER_BLOCKED_ON_HOSTING')<boolean>();
