import { StockItem } from '@anm/api/modules/stocks';
import { getStockPrice } from '@anm/components/stocks/StockList';
import selectProps from '@anm/helpers/store/selectProps';
import { createSelector } from 'reselect';


import { ApplicationState } from '../../types';
import { userSelectors } from '../user';

import { StockPriceProps } from './types';

export const selectStockList = ({ stocks }: ApplicationState) =>
  stocks.stockItems.list;

export const selectStockIsPending = ({ stocks }: ApplicationState) =>
  stocks.stockItems.isPending;

export const selectStocksCount = ({ stocks }: ApplicationState) =>
  stocks.stockItems.count;

export const selectStocksParams = ({ stocks }: ApplicationState) =>
  stocks.stockItems.params;

export const selectStockById = createSelector(
  selectStockList,
  selectProps<string>(),
  (stockList, stockId) => stockList.find(s => s.id === stockId) as StockItem
);

export const selectStockPrice = createSelector(
  userSelectors.selectSubscriptionDetails,
  userSelectors.selectStocksFeature,
  selectProps<StockPriceProps>(),
  getStockPrice
);

export const selectYoutubeChannelId = ({ stocks }: ApplicationState) =>
  stocks.youtubeChannelChecker.channelId;

export const selectYoutubeChannelEntity = ({ stocks }: ApplicationState) =>
  stocks.youtubeChannelChecker;

export const selectYoutubeDisputeClaimIsPending = ({
  stocks
}: ApplicationState) => stocks.youtubeDisputeClaim.isPending;

export const selectYoutubeDisputeClaimError = ({ stocks }: ApplicationState) =>
  stocks.youtubeDisputeClaim.error;

export const selectYoutubeDisputeClaimEntity = ({ stocks }: ApplicationState) =>
  stocks.youtubeDisputeClaim;
